import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import {
  getActionsByType,
  getKeywordsByType,
  IProduct,
} from "integrations/firebase/collections";
import { actionInProgress } from "utils/status";
import { Modal } from "components/modal/Modal";
import { getAllDescriptionActions } from "reedy-data";
import { DescriptionType } from "api/client/products";
import { GENERATE_METADATA_BOOKS_LIMIT } from "../../../../shared/constants";
import { BulkGenerateOption } from "./BulkGenerateOption";
import { AutofillSwitch } from "./AutofillSwitch";
import { DescriptionTypeMultiSelect } from "./DescriptionTypeMultiSelect";

export function BulkGenerateButton({
  products,
  generateKeywords,
  generateDescriptions,
}: {
  products: IProduct[];
  generateKeywords: (products: IProduct[], autofillFinalList?: boolean) => void;
  generateDescriptions: (
    products: IProduct[],
    descriptionTypes: DescriptionType[],
  ) => void;
}) {
  const { t } = useTranslation(["projectDetails", "general"]);
  const [autofillFinalList, setAutofillFinalList] = useState(false);
  const [selectedDescriptionTypes, setSelectedDescriptionTypes] = useState<
    DescriptionType[]
  >([]);

  const [
    showDescriptionsGenerationWarning,
    setShowDescriptionsGenerationWarning,
  ] = useState(false);

  const [showKeywordsGenerationWarning, setShowKeywordsGenerationWarning] =
    useState(false);

  const hasGeneratedKeywords = products.some((p) =>
    Boolean(getKeywordsByType(p).generated.length),
  );

  const hasGeneratedDescriptions = products.some((p) => {
    const descriptions = p.descriptions || [];
    return descriptions.some((d) => Boolean(d.textGenerated?.length));
  });

  const existingDescriptionTypes = useMemo(() => {
    const types: Array<DescriptionType> = [];
    products.forEach((product) => {
      const descriptions = product.descriptions || [];
      descriptions.forEach((description) => {
        if (description.textType && description.audiences?.length) {
          description.audiences.forEach((audience) => {
            if (description.textType) {
              types.push({
                textType: description.textType,
                audience,
              });
            }
          });
        }
      });
    });
    const uniqueTypes = types.reduce((unique, desc) => {
      const key = `${desc.textType}_${desc.audience}`;
      return unique.set(key, desc);
    }, new Map<string, DescriptionType>());
    const uniqueTypesArray = Array.from(uniqueTypes.values());
    return uniqueTypesArray;
  }, [products]);

  const handleGenerateKeywordsClick = useCallback(
    async (skipWarning = false) => {
      if (!skipWarning) {
        setShowKeywordsGenerationWarning(true);
        return;
      }

      setShowKeywordsGenerationWarning(false);

      await generateKeywords(products, autofillFinalList);
    },
    [generateKeywords, products, autofillFinalList],
  );

  const handleGenerateDescriptionsClick = useCallback(
    async (skipWarning = false) => {
      if (!skipWarning) {
        setShowDescriptionsGenerationWarning(true);
        return;
      }

      setShowDescriptionsGenerationWarning(false);

      await generateDescriptions(products, selectedDescriptionTypes);
    },
    [generateDescriptions, products, selectedDescriptionTypes],
  );

  const { generateAllEnabled, isGenerating } = useMemo(() => {
    if (!products?.length) {
      return { generateAllEnabled: false, isGenerating: false };
    }

    const hasRequested = products.some((p) => {
      const { keywords } = getActionsByType(p);

      const descriptionActions = getAllDescriptionActions(p);
      const descriptionActionsValues = descriptionActions
        ? Object.values(descriptionActions)
        : [];
      const descriptionActionsValuesGenerate = descriptionActionsValues
        .map((a) => a?.generate)
        .filter((a) => a !== undefined);

      return actionInProgress(
        ...descriptionActionsValuesGenerate,
        keywords?.generate,
      );
    });

    const hasErrors = products.some((p) => p.nonExistent);

    if (hasErrors) {
      return { generateAllEnabled: false, isGenerating: hasRequested };
    }

    return { generateAllEnabled: !hasRequested, isGenerating: hasRequested };
  }, [products]);

  const disabled =
    isGenerating ||
    !generateAllEnabled ||
    products.length > GENERATE_METADATA_BOOKS_LIMIT;

  const getTooltipContent = () => {
    if (products.length === 0) {
      return t("projectDetails:buttons.generate.disabledNoBooks", {
        count: GENERATE_METADATA_BOOKS_LIMIT,
      });
    }

    if (products.length > GENERATE_METADATA_BOOKS_LIMIT) {
      return t("projectDetails:buttons.generate.disabledLimitBooks", {
        count: GENERATE_METADATA_BOOKS_LIMIT,
      });
    }

    if (!disabled) {
      return t("projectDetails:buttons.generateAll.tooltips.enabled");
    }

    if (isGenerating) {
      return t("projectDetails:buttons.generateAll.tooltips.generating");
    }

    return null;
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={disabled}
          className="disabled:text-secondary-400 text-secondary-700 group"
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="rounded-lg border flex-1 flex group-aria-expanded:border-secondary-600 cursor-pointer transition-colors">
                <div className="whitespace-nowrap font-normal flex items-center px-5 text-m">
                  {isGenerating
                    ? t("projectDetails:buttons.generateAll.generating")
                    : t("projectDetails:buttons.generateAll.metadata")}
                </div>

                <div className="transition-colors w-11 h-full border-l aspect-square flex items-center justify-center group-aria-expanded:border-l-secondary-600">
                  <ChevronDown className="w-5 h-5 transition-transform group-aria-expanded:rotate-180" />
                </div>
              </div>
            </TooltipTrigger>

            {getTooltipContent() && (
              <TooltipContent
                data-testid="generate-all-tooltip"
                side="bottom"
                className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
                sideOffset={6}
              >
                <TooltipArrow
                  className="fill-white drop-shadow-sm"
                  width={12}
                  height={6}
                />
                {getTooltipContent()}
              </TooltipContent>
            )}
          </Tooltip>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          align="center"
          className="popover-content-width-full"
        >
          <BulkGenerateOption
            onClick={() => handleGenerateKeywordsClick(false)}
            label={t("projectDetails:buttons.generateAll.items.keywords")}
          />
          <BulkGenerateOption
            onClick={() => handleGenerateDescriptionsClick(false)}
            label={t("projectDetails:buttons.generateAll.items.descriptions")}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        id="overwrite-description"
        variant={hasGeneratedDescriptions ? "warning" : "primary"}
        title={
          hasGeneratedDescriptions
            ? t("projectDetails:modals.overwriteDescriptions.title")
            : t("projectDetails:modals.generateDescriptions.title")
        }
        description={
          hasGeneratedDescriptions
            ? t("projectDetails:modals.overwriteDescriptions.description")
            : t("projectDetails:modals.generateDescriptions.description")
        }
        confirmLabel={
          hasGeneratedDescriptions
            ? t("projectDetails:modals.overwriteDescriptions.actions.confirm")
            : t("general:buttons.generate")
        }
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showDescriptionsGenerationWarning}
        onOpenChange={() => setShowDescriptionsGenerationWarning(false)}
        onConfirm={() => handleGenerateDescriptionsClick(true)}
        disabledConfirm={selectedDescriptionTypes.length === 0}
      >
        <DescriptionTypeMultiSelect
          selectedDescriptionTypes={selectedDescriptionTypes}
          setSelectedDescriptionTypes={setSelectedDescriptionTypes}
          existingDescriptionTypes={existingDescriptionTypes}
        />
      </Modal>

      <Modal
        id="overwrite-keywords"
        variant={hasGeneratedKeywords ? "warning" : "primary"}
        title={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.title")
            : t("projectDetails:modals.generateKeywords.title")
        }
        description={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.description")
            : t("projectDetails:modals.generateKeywords.description")
        }
        confirmLabel={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.actions.confirm")
            : t("general:buttons.generate")
        }
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showKeywordsGenerationWarning}
        onOpenChange={() => setShowKeywordsGenerationWarning(false)}
        onConfirm={() => handleGenerateKeywordsClick(true)}
      >
        <AutofillSwitch
          checked={autofillFinalList}
          onCheckedChange={setAutofillFinalList}
        />
      </Modal>
    </>
  );
}
