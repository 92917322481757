import { useCollectionData } from "react-firebase-hooks/firestore";
import { limit as take, orderBy, query, where } from "firebase/firestore";
import { getGenerationRequestsDescriptionsCollection } from "../integrations/firebase/collections";

export const useProductDescriptionGenerations = (
  productId?: string,
  organisationId?: string,
  limit: number = 1,
) => {
  return useCollectionData(
    productId && organisationId
      ? query(
          getGenerationRequestsDescriptionsCollection(),
          where("organisation.id", "==", organisationId),
          where("entityId", "==", productId),
          take(limit),
          orderBy("createdAt", "desc"),
        )
      : undefined,
  );
};
