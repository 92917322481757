import {
  collection,
  CollectionReference,
  doc,
  FirestoreError,
  getDocs,
  onSnapshot,
  query,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  runTransaction,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import { logError } from "shared/services/ErrorReporting";
import type { IProduct } from "reedy-data";
import {
  FirestoreProductSubject,
  FirestoreProductActionsKeywords,
  FirestoreProductActionsDescriptions,
  FirestoreProductActionsThema,
} from "reedy-data/api";
import { nonNullable } from "../../../utils/types";

export {
  getDefaultDescription,
  getDescriptionActions,
  getDefaultDescriptionActions,
} from "reedy-data";
export type { IProduct } from "reedy-data";

export const getActionsByType = (
  product?: IProduct,
): {
  keywords?: FirestoreProductActionsKeywords["actions"];
  descriptions?: FirestoreProductActionsDescriptions["actions"];
  thema?: FirestoreProductActionsThema["actions"];
} => ({
  keywords: product?.actions?.keywords?.actions,
  descriptions: product?.actions?.descriptions?.actions,
  thema: product?.actions?.thema?.actions,
});

const productConverter = {
  toFirestore(product: IProduct) {
    return product;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<IProduct>): IProduct {
    const data = snapshot.data();

    const { identifier } = data;

    return {
      ...data,
      id: snapshot.id,
      isbn: identifier,
    };
  },
};

export const getProductsCollection = () =>
  collection(db, "productsNew").withConverter(productConverter);

export enum SubjectSchemeIdentifier {
  ThemaSubjectCategory = "themaSubjectCategory",
  ThemaPlaceQualifier = "themaPlaceQualifier",
  ThemaTimePeriodQualifier = "themaTimePeriodQualifier",
  ThemaStyleQualifier = "themaStyleQualifier",
  ThemaEducationalPurposeQualifier = "themaEducationalPurposeQualifier",
  ThemaInterestAgeSpecialInterestQualifier = "themaInterestAgeSpecialInterestQualifier",
  Keyword = "keyword",
}

export const qualifierTypes = [
  SubjectSchemeIdentifier.ThemaPlaceQualifier,
  SubjectSchemeIdentifier.ThemaTimePeriodQualifier,
  SubjectSchemeIdentifier.ThemaStyleQualifier,
  SubjectSchemeIdentifier.ThemaEducationalPurposeQualifier,
  SubjectSchemeIdentifier.ThemaInterestAgeSpecialInterestQualifier,
];

export const subjectsTypes = [SubjectSchemeIdentifier.ThemaSubjectCategory];

export type ProductSubjectType = "generated" | "database" | "final";

export const keywordsToText = (keywords: FirestoreProductSubject[]) =>
  keywords.map((keyword) => keyword.subjectHeadingText).filter(nonNullable);

const BATCH_SIZE = 30;

const createBatches = (isbns: string[]): string[][] =>
  isbns.reduce(
    (acc, _, i) =>
      i % BATCH_SIZE === 0
        ? (acc.push(isbns.slice(i, i + BATCH_SIZE)), acc)
        : acc,
    [] as string[][],
  );

const queryWithBatchedIsbns = (
  isbns: string[],
  products: CollectionReference<IProduct, IProduct>,
  ...queryConstraints: QueryConstraint[]
): Query[] => {
  const batches = createBatches(isbns);
  const queries: Query<IProduct>[] = [];

  batches.forEach((batch) => {
    const q = query(
      products,
      ...[...queryConstraints, where("identifier", "in", batch)],
    );
    queries.push(q);
  });

  return queries;
};

export const watchProducts = (
  isbns: string[],
  organisationId: string,
  onNext: (products: IProduct[]) => void,
  onError?: (error: FirestoreError) => void,
) => {
  const allProducts: IProduct[] = [];

  const queries = queryWithBatchedIsbns(
    isbns,
    getProductsCollection(),
    where("organisation.id", "==", organisationId),
  );

  const unsubscribeFunctions = queries.map((q) =>
    onSnapshot(
      q,
      (querySnapshot) => {
        const newProducts: IProduct[] =
          querySnapshot.docs?.map(
            (docum: QueryDocumentSnapshot) => docum.data() as IProduct,
          ) || [];

        // Update existing products and add new ones
        newProducts.forEach((product) => {
          const productIndex = allProducts.findIndex(
            (p) => p.id === product.id,
          );
          if (productIndex !== -1) {
            allProducts[productIndex] = product;
          } else {
            allProducts.push(product);
          }
        });

        onNext([...allProducts]);
      },
      onError,
    ),
  );

  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
};

export const watchProduct = (
  id: string,
  onNext: (products: IProduct | null) => void,
  onError?: (error: FirestoreError) => void,
) => {
  const docRef = doc(getProductsCollection(), id);
  const unsubscribe = onSnapshot(
    docRef,
    (docSnapshot) => {
      if (docSnapshot?.exists?.()) {
        const data = docSnapshot.data();
        onNext(data as IProduct);
      } else {
        onNext(null);
      }
    },
    onError,
  );
  return unsubscribe;
};

export const getProductsByIsbns = async (
  organisationId: string,
  isbns: string[],
): Promise<IProduct[]> => {
  const allProducts: IProduct[] = [];

  try {
    await Promise.all(
      queryWithBatchedIsbns(
        isbns,
        getProductsCollection(),
        where("organisation.id", "==", organisationId),
      ).map(async (q) => {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((docum) => {
          allProducts.push(docum.data() as IProduct);
        });
      }),
    );
  } catch (error) {
    logError("Error fetching products by ISBNs:", { originalException: error });
    throw error; // Re-throw the error after logging it
  }

  return allProducts;
};

export type UpdateFields = {
  [key: string]: any;
};
export const updateProducts = async (
  products: IProduct[],
  productFields: UpdateFields,
) => {
  const batch = writeBatch(db);
  products.forEach((product) => {
    if (product?.id) {
      const productRef = doc(getProductsCollection(), product.id);
      batch.update(productRef, {
        ...productFields,
        updatedAt: serverTimestamp(),
      });
    }
  });
  await batch.commit();
};

export const updateProductInTransaction = async (
  productId: string,
  productFields: (product?: IProduct) => UpdateFields,
) => {
  const productRef = doc(getProductsCollection(), productId);

  await runTransaction(db, async (transaction) => {
    const product = await transaction.get(productRef);

    const updates = productFields(product.data());

    transaction.update(productRef, {
      ...updates,
      updatedAt: serverTimestamp(),
    });
  });

  await updateDoc(productRef, {
    ...productFields,
    updatedAt: serverTimestamp(),
  });
};

export const updateProduct = async (
  productId: string,
  productFields: UpdateFields,
) => {
  const productRef = doc(getProductsCollection(), productId);
  await updateDoc(productRef, {
    ...productFields,
    updatedAt: serverTimestamp(),
  });
};

export const getKeywordsByType = (
  product?: IProduct,
): Record<ProductSubjectType, FirestoreProductSubject[]> => ({
  database: (product?.subjects?.database ?? []).filter(
    (subject) =>
      subject.subjectSchemeIdentifier === SubjectSchemeIdentifier.Keyword,
  ),
  generated: (product?.subjects?.generated ?? []).filter(
    (subject) =>
      subject.subjectSchemeIdentifier === SubjectSchemeIdentifier.Keyword,
  ),
  final: (product?.subjects?.final ?? []).filter(
    (subject) =>
      subject.subjectSchemeIdentifier === SubjectSchemeIdentifier.Keyword,
  ),
});

export const getKeywordsBySubjectType = (
  type: ProductSubjectType,
  product?: IProduct,
): string[] => {
  const keywords = getKeywordsByType(product)[type];
  return keywordsToText(keywords);
};

export const getDatabaseKeywords = (product?: IProduct) =>
  getKeywordsBySubjectType("database", product);

export const getGeneratedKeywords = (product?: IProduct) =>
  getKeywordsBySubjectType("generated", product);

export const getFinalKeywords = (product?: IProduct) =>
  getKeywordsBySubjectType("final", product);
