import { memo, useEffect, useMemo } from "react";
import { useBacklist } from "contexts/BacklistContext";
import { useErrorToast } from "hooks/useErrorToast";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Filter, SearchQuery, SearchType } from "hooks/search/types";
import debounce from "lodash/debounce";
import { SearchTypeDropdown } from "./input/SearchTypeDropdown";
import { SearchFilters } from "./filters/SearchFilters";
import { SearchInput } from "./input/SearchInput";
import { SearchResultsCountDropdown } from "./input/SearchResultsCountDropdown";
import { isValidFilter } from "./filters/utils";
import { defaultFilterValues } from "./filters/constants";

interface SearchToolbarProps {
  onSearch(): void;
}

export const SearchToolbar = memo(({ onSearch }: SearchToolbarProps) => {
  const { searchQuery, setSearchQuery, error } = useBacklist();

  const form = useForm<SearchQuery>({
    defaultValues: {
      ...searchQuery,
      filters: {
        ...defaultFilterValues,
        ...searchQuery.filters,
      },
    },
  });

  const { control, watch, setValue } = form;

  const setQueryDebounced = useMemo(
    () => debounce(setSearchQuery, 300),
    [setSearchQuery],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const updatedQuery = { ...value, page: null };
      if (name?.startsWith("filters.")) {
        const [, filterName] = name.split(".") as [
          string,
          keyof SearchQuery["filters"],
        ];
        const filterValue = value.filters?.[filterName] as Filter;

        if (filterName && isValidFilter(filterValue)) {
          setSearchQuery(updatedQuery as SearchQuery);
          onSearch();
        }

        if (
          !isValidFilter(filterValue) &&
          updatedQuery.filters &&
          !name?.includes(".tab") &&
          !name?.includes(".range")
        ) {
          const defaultValue = defaultFilterValues[filterName];
          if (defaultValue && defaultValue.type === filterValue.type) {
            updatedQuery.filters[filterName] = { ...defaultValue } as any;
          }
          setSearchQuery(updatedQuery as SearchQuery);
          onSearch();
        }
        return;
      }

      setQueryDebounced(updatedQuery as SearchQuery);
      onSearch();
    });

    return () => {
      subscription.unsubscribe();
      setQueryDebounced.cancel();
    };
  }, [watch, setSearchQuery, onSearch, setQueryDebounced, setValue]);

  const type = useWatch({ control, name: "type" });

  useErrorToast(error);

  return (
    <FormProvider {...form}>
      <div className="flex space-x-3 items-center">
        <form className="flex flex-row w-full relative h-10 space-x-2">
          {type !== SearchType.ISBN && <SearchFilters />}
          <div className="flex flex-row w-full">
            <SearchTypeDropdown control={control} />
            <SearchInput name="query" />
          </div>

          {type === SearchType.SEMANTIC && (
            <SearchResultsCountDropdown control={control} />
          )}
        </form>
      </div>
    </FormProvider>
  );
});

SearchToolbar.displayName = "SearchToolbar";
