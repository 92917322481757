import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  updateProfile,
  User,
  UserCredential,
  onIdTokenChanged,
} from "firebase/auth";
import { doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "integrations/firebase/auth";
import {
  getOrganisationsCollection,
  getUsersCollection,
  IUser,
} from "integrations/firebase/collections";
import { GlobalLoading } from "components/loading";
import { logError } from "shared/services/ErrorReporting";
import { toast } from "utils/toast";
import { useTranslation } from "react-i18next";
import { FirestoreOrganisation } from "__generated__/models";
import { jwtDecode } from "jwt-decode";

type IUserSettings = Pick<IUser, "settings">["settings"];

export interface IAuthContext {
  userData: IUser | null;
  organisation: FirestoreOrganisation | null;
  authUser?: User | null;
  loading: boolean;
  error?: Error;
  isLoggedIn: boolean;
  authToken?: string;

  signInWithOauth: (type: OAuthProviderType) => Promise<UserCredential>;

  sendLoginLink: (email: string) => Promise<void>;

  signOut: () => Promise<void>;

  updateUserProfile: (profile: UserProfile) => Promise<void>;

  switchOrganisation: (organisation: FirestoreOrganisation) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>(undefined as never);

export const useAuth = () => useContext(AuthContext);

const OAUTH_PROVIDERS = {
  google: new GoogleAuthProvider(),
};

type OAuthProviderType = keyof typeof OAUTH_PROVIDERS;

export interface UserProfile {
  firstName: string;
  lastName: string;
  photoUrl?: string;
}

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(["login"]);

  const [authToken, setAuthToken] = useState<string>();
  const [userData, setUserData] = useState<IUser | null>(null);
  const [organisation, setOrganisation] =
    useState<FirestoreOrganisation | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [authUser, loading] = useAuthState(auth);
  const [error, setError] = useState<Error>();

  const signOut = useCallback(() => auth.signOut(), []);

  const updateUserSettings = useCallback(
    async (settings: Partial<IUserSettings>) => {
      try {
        const userRef = doc(getUsersCollection(), authUser?.uid);
        await setDoc(userRef, { settings }, { merge: true });
      } catch (err) {
        logError(err);
        setError(err as Error);
      }
    },
    [authUser],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!authUser) {
      setInitialized(true);
      setUserData(null);
      return;
    }

    const uid = auth.currentUser?.uid;
    const userRef = doc(getUsersCollection(), uid);

    const userSubscription = onSnapshot(userRef, async (document) => {
      const user = document.data();

      if (!user) {
        logError(`User not found for id: ${uid}`);
        await signOut();
        setUserData(null);
        setInitialized(true);
        return;
      }

      setUserData(user);

      // update the user settings with the accepted terms version
      const acceptedTermsVersion = window.localStorage.getItem(
        "acceptedTermsVersion",
      );

      if (acceptedTermsVersion) {
        updateUserSettings({
          acceptedTermsVersion,
        });
        window.localStorage.removeItem("acceptedTermsVersion");
      }
    });

    return () => {
      if (userSubscription) {
        userSubscription();
      }
    };
  }, [authUser, signOut, loading, updateUserSettings]);

  const updateUserProfile = useCallback(
    async (profile: UserProfile) => {
      if (!authUser) {
        return;
      }

      try {
        const userRef = doc(getUsersCollection(), authUser.uid);

        await updateProfile(authUser, {
          displayName: `${profile.firstName} ${profile.lastName}`,
          photoURL: profile.photoUrl,
        });

        await updateDoc(userRef, {
          givenName: profile.firstName,
          familyName: profile.lastName,
        });
      } catch (err) {
        setError(err as Error);
        logError(err);
      }
    },
    [authUser],
  );

  const switchOrganisation = useCallback(
    async (newOrganisation: FirestoreOrganisation) => {
      if (!authUser) {
        throw new Error("No authenticated user");
      }

      const userRef = doc(getUsersCollection(), authUser.uid);
      await updateDoc(userRef, {
        organisation: {
          id: newOrganisation.id,
          name: newOrganisation.name,
        },
      });
    },
    [authUser],
  );

  useEffect(() => {
    if (!userData?.organisation) {
      setOrganisation(null);
      return;
    }

    const organisationRef = doc(
      getOrganisationsCollection(),
      userData.organisation.id,
    );

    const unsubscribeOrganisation = onSnapshot(organisationRef, (document) => {
      const org = document.data();
      setInitialized(true);
      if (!org) {
        logError(
          `Organisation not found for organisationId: ${userData.organisation.id}, userId: ${userData.id}`,
        );
        setOrganisation(null);
        return;
      }
      setOrganisation(org);
    });

    return () => {
      if (unsubscribeOrganisation) {
        unsubscribeOrganisation();
      }
    };
  }, [userData]);

  const signInWithOauth = useCallback(async (type: OAuthProviderType) => {
    const provider = OAUTH_PROVIDERS[type];

    if (!provider) {
      throw new Error(`No provider found for type ${type}`);
    }

    return signInWithPopup(auth, provider);
  }, []);

  const sendLoginLink = useCallback(async (email: string) => {
    try {
      window.localStorage.setItem("emailForSignIn", email);

      return await sendSignInLinkToEmail(auth, email, {
        handleCodeInApp: true,
        url: `${window.location.origin}/login-link`,
      });
    } catch (e) {
      window.localStorage.removeItem("emailForSignIn");
      throw e;
    }
  }, []);

  const hasNoOrganisation = userData && !userData.organisation;

  useEffect(() => {
    if (!hasNoOrganisation) {
      return;
    }

    signOut().then();
    setInitialized(true);

    toast.error(t("login:error.noOrganisation"));
  }, [t, signOut, hasNoOrganisation]);

  useEffect(() => {
    if (!authUser) {
      setAuthToken(undefined);
      return;
    }

    const updateToken = async (user: User, forceUpdate: boolean = false) => {
      try {
        const token = await user.getIdToken(forceUpdate);
        setAuthToken(token);
        return token;
      } catch (err) {
        logError(err);
        setAuthToken(undefined);
      }
    };

    // Fetch initial token
    updateToken(authUser);

    // Set up listener for token changes
    let refreshTimeout: NodeJS.Timeout;
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await updateToken(user, true);
        if (!token) return;

        // Decode the token to get its expiration time
        const decodedToken = jwtDecode(token);
        if (!decodedToken.exp) {
          logError("Token decoding failed.");
          return;
        }
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const timeToRefresh = expirationTime - Date.now() - 5 * 60 * 1000; // Refresh 5 minutes before expiry

        // Set up refresh before token expires
        refreshTimeout = setTimeout(() => {
          updateToken(user, true);
        }, timeToRefresh);
      } else {
        setAuthToken(undefined);
      }
    });

    // Clean up subscription and timeout
    return () => {
      unsubscribe();
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [authUser]);

  const isLoggedIn = !!authUser;
  const authProviderValue = useMemo(
    () => ({
      authUser,
      userData,
      organisation,
      loading,
      error,
      isLoggedIn,
      signInWithOauth,
      signOut,
      sendLoginLink,
      updateUserProfile,
      authToken,
      switchOrganisation,
    }),
    [
      authUser,
      organisation,
      userData,
      loading,
      error,
      isLoggedIn,
      signInWithOauth,
      signOut,
      sendLoginLink,
      updateUserProfile,
      authToken,
      switchOrganisation,
    ],
  );

  if (loading || !initialized) {
    return <GlobalLoading />;
  }

  return (
    <AuthContext.Provider value={authProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};
