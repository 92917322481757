import { Error } from "components/icon/Error";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";

export function ContactUsError() {
  const { t } = useTranslation(["contactUs", "error"]);
  return (
    <div className="w-full min-h-[50vh] pt-24 flex justify-center items-center">
      <div className="pb-4 flex flex-col items-center text-center gap-6 max-w-lg">
        <div className="mb-4">
          <Error />
        </div>

        <p className="pb-1 text-2xl font-semibold">{t("error.title")}</p>
        <p className="text-m text-secondary-400 font-light">
          {t("error.description")}
        </p>
        <a href="mailto:reedy.support@chaptr.com" data-testid="support-action">
          <Button
            label={t("error:actions.support")}
            variant="tertiary"
            size="small"
            className="!text-s"
          />
        </a>
      </div>
    </div>
  );
}
