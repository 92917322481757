import { Link } from "react-router-dom";
import { ArrowRight, SquareStack } from "lucide-react";
import { Button } from "components/button";
import { useSearch } from "hooks/search/useSearch";
import { FilterType, SearchFilterComparisonOperator } from "hooks/search/types";
import { useTranslation } from "react-i18next";
import { RoutePath } from "shared/constants";
import { TicketCategory } from "integrations/zendesk";

const KEYWORD_COUNT = 5;

const KEYWORDS_QUERY_LESS_THAN = {
  filters: {
    keywordCount: {
      value: KEYWORD_COUNT,
      comparisonOperator: SearchFilterComparisonOperator.LESS,
      type: FilterType.NUMERIC,
    },
  },
} as const;

export const BacklistLinks = () => {
  const { data } = useSearch(KEYWORDS_QUERY_LESS_THAN);
  const { t } = useTranslation(["home"]);

  const contactUsLink = `${RoutePath.ContactUs}?${new URLSearchParams({
    ticketCategory: TicketCategory.feature_request,
    message: t("home:backlist.featureRequestMessage"),
  })}`;

  return (
    <div className="space-y-4">
      <h2 className="text-neutral-700 text-xl font-semibold">
        {t("home:backlist.links.title")}
      </h2>

      <div className="grid lg:grid-cols-[auto,1fr] gap-4 grid-rows-[90px]">
        <Link
          to={`/books?filter=keywordCount.lt:${KEYWORD_COUNT}`}
          className="shadow-sm border border-secondary-50 rounded-xl flex items-center justify-between px-6 py-3 space-x-8"
        >
          <div className="flex items-center space-x-4">
            <div className="bg-[#FDF0F6] rounded-full h-12 w-12 shrink-0 flex items-center justify-center">
              <SquareStack className="w-5 h-5 text-[#F32C69]" />
            </div>

            <div className="space-y-0.5">
              <div className="text-secondary-900">
                {data?.found === undefined && (
                  <div className="bg-secondary-50 rounded-md mr-10 animate-pulse">
                    &nbsp;
                  </div>
                )}
                {data?.found !== undefined &&
                  t("home:backlist.keywords.title", {
                    count: data?.found ?? 0,
                  })}
              </div>
              <div className="text-sm text-secondary-500">
                {t("home:backlist.keywords.subtitle", { count: KEYWORD_COUNT })}
              </div>
            </div>
          </div>

          <Button variant="ghost" className="text-primary-600 text-sm p-0">
            {t("home:backlist.keywords.action")}
            <ArrowRight className="w-5 h-5" />
          </Button>
        </Link>

        <div className="flex-1 bg-secondary-25 rounded-lg flex items-center justify-center px-6 py-3 space-x-3">
          <div>🚀</div>
          <div className="text-s">
            <span className="font-medium">{t("home:backlist.comingSoon")}</span>
          </div>
          <Link
            to={contactUsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <Button variant="tertiary" size="small">
              {t("general:contactUs")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
