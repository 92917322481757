import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { AlertTriangle, XIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { UserRole } from "__generated__/api";

export const OrganisationBanner = () => {
  const { userData, organisation } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation(["components"]);

  const isSuperAdmin = userData?.roles?.includes(UserRole.SUPER_ADMIN);

  useEffect(() => {
    const orgId = organisation?.id;
    if (!orgId) {
      return;
    }

    const dismissedOrg = localStorage.getItem("dismissedOrgBanner") || "";
    if (dismissedOrg !== orgId) {
      setIsVisible(true);
    }
  }, [organisation?.id]);

  const handleDismiss = () => {
    const orgId = organisation?.id;
    if (!orgId) return;

    localStorage.setItem("dismissedOrgBanner", orgId);
    setIsVisible(false);
  };

  if (!isVisible || !organisation) {
    return null;
  }

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <div className="bg-system-error/10 px-4 py-2 flex items-center justify-center relative">
      <div className="flex items-center gap-2">
        <AlertTriangle className="h-4 w-4 text-system-error" />
        <p className="text-xs text-system-error">
          {t("components:banner.organisation.message", {
            name: organisation.name,
          })}
        </p>
      </div>
      <button
        type="button"
        onClick={handleDismiss}
        className="text-system-error hover:text-system-error/80 transition-colors absolute right-4"
        aria-label="Dismiss banner"
      >
        <XIcon className="h-4 w-4" />
      </button>
    </div>
  );
};
