import { FirestoreGenerationStatus } from "__generated__/api";
import { FirestoreProductActionsValue } from "__generated__/models";

export function statusInProgress(status?: FirestoreGenerationStatus | null) {
  return (
    status &&
    [
      FirestoreGenerationStatus.REQUESTED,
      FirestoreGenerationStatus.GENERATING,
      FirestoreGenerationStatus.PROCESSING,
    ].includes(status)
  );
}

export function actionInProgress(
  ...actions: Array<FirestoreProductActionsValue | undefined | null>
) {
  return actions.some((s) => statusInProgress(s?.status));
}

export function actionErrored(
  ...actions: Array<FirestoreProductActionsValue | undefined | null>
) {
  return actions.some((s) => s?.status === FirestoreGenerationStatus.ERROR);
}
