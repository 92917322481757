import React from "react";
import { FirestoreProduct } from "__generated__/models";
import { useCurrentProduct } from "../../contexts/CurrentProductContext";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { ActivityIndicator } from "./activityIndicator";

export function ProjectActivityIndicator() {
  const { generatingProducts } = useCurrentProduct() as any;

  const keywords =
    (generatingProducts?.filter &&
      generatingProducts?.filter(
        (p: FirestoreProduct) => p.actions?.keywords?.active,
      )) ||
    [];

  const descriptions =
    (generatingProducts?.filter &&
      generatingProducts?.filter(
        (p: FirestoreProduct) => p.actions?.descriptions?.active,
      )) ||
    [];

  const thema =
    (generatingProducts?.filter &&
      generatingProducts?.filter(
        (p: FirestoreProduct) => p.actions?.thema?.active,
      )) ||
    [];

  const show = keywords?.length || descriptions?.length || thema?.length;

  return (
    <Tooltip>
      <TooltipTrigger>
        <ActivityIndicator show={show} />
      </TooltipTrigger>
      <TooltipContent>
        {keywords?.length ? (
          <div>
            <p className="text-xs font-semibold">Generating keywords</p>
            {keywords.map((product: any, i: any) => (
              <p key={product.id} className="text-xs">
                {`${i + 1}. ${product.title}`}
              </p>
            ))}
          </div>
        ) : null}
        {descriptions?.length ? (
          <div>
            <p className="text-xs font-semibold">Generating description</p>
            {descriptions.map((product: any, i: any) => (
              <p key={product.id} className="text-xs">
                {`${i + 1}. ${product.title}`}
              </p>
            ))}
          </div>
        ) : null}
        {thema?.length ? (
          <div>
            <p className="text-xs font-semibold">Generating THEMA codes</p>
            {thema.map((product: any, i: any) => (
              <p key={product.id} className="text-xs">
                {`${i + 1}. ${product.title}`}
              </p>
            ))}
          </div>
        ) : null}
      </TooltipContent>
    </Tooltip>
  );
}
