import { useTranslation } from "react-i18next";
import { Icon } from "components/icon/Icon";
import { useCallback } from "react";
import { FirestoreGenerationStatus } from "__generated__/api";
import { Button } from "components/button";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";
import { useApi } from "hooks/useApi";
import { downloadFile } from "utils/helpers";
import { formatDateTime } from "shared/utils";

interface Evaluation {
  id?: string;
  status?: FirestoreGenerationStatus;
  numberOfBooks?: number;
  data?: {
    file?: { [key: string]: unknown } | null;
  };
  createdAt?: Date;
}

export const EvaluationCard = ({
  id,
  status,
  numberOfBooks,
  createdAt,
  data,
}: Evaluation) => {
  const { t } = useTranslation(["books"]);
  const api = useApi();

  const handleDownload = useCallback(async () => {
    if (!data?.file?.url || !id) return;

    try {
      // Get the latest evaluation data to ensure we have the most up-to-date file URL
      const response = await api.evaluation.getEvaluation(id);
      if (!response) {
        throw new Error("Download failed. Please try again.");
      }
      const fileUrl = response.file?.url as string;
      const fileName = (response.file?.name as string) || "evaluation.xlsx";

      if (!fileUrl) {
        throw new Error("File URL not found.");
      }
      downloadFile(fileUrl, fileName);
    } catch (error) {
      logError(`Download failed: ${error}`);
      toast.error(t("books:evaluation.downloadError"));
    }
  }, [api.evaluation, id, data?.file, t]);

  const createdAtStr = createdAt
    ? formatDateTime(createdAt)
    : t("books:evaluation.card.unknownCreatedAt");

  return (
    <div className="shadow-sm border-border border rounded-md space-x-3 flex p-3 items-center">
      <div className="border rounded-md p-2.5">
        <Icon name="chart-pie" />
      </div>
      <div className="space-y-1 flex-1">
        <div className="text-sm font-medium">{createdAtStr}</div>
        <div className="text-xs text-secondary-400 flex space-x-8">
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t("books:evaluation.card.numberOfBooks")}:
            </span>
            <span className="font-semibold">{numberOfBooks}</span>
          </div>
          {status && (
            <div className="flex items-center space-x-2">
              <span className="font-medium">
                {t("books:evaluation.card.status")}:
              </span>
              <span className="font-semibold">{status}</span>
            </div>
          )}
        </div>
      </div>
      {status === FirestoreGenerationStatus.COMPLETED && (
        <Button
          variant="tertiary"
          size="small"
          icon="download"
          onClick={handleDownload}
          testId="evaluation-card-download"
        />
      )}
    </div>
  );
};
