import React from "react";
import { useFormContext } from "react-hook-form";

import { FilterType } from "hooks/search/types";
import { DateFilter } from "pages/Books/components/filters/dateFilter/DateFilter";
import { NumericFilter } from "./NumericFilter";

interface FilterTypeSelectorProps {
  type: FilterType;
  name: string;
}

export const FilterTypeSelector: React.FC<FilterTypeSelectorProps> = ({
  type,
  name,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message;
  const errorMessage = typeof error === "string" ? error : undefined;

  switch (type) {
    case FilterType.NUMERIC:
      return <NumericFilter name={name} error={errorMessage} />;
    case FilterType.DATE_RANGE:
      return <DateFilter name={name} />;
    default:
      return null;
  }
};
