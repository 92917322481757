import { useState } from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { useOrganisation } from "contexts/OrganisationProvider";
import { toast } from "utils/toast";
import { Modal } from "components/modal/Modal";
import { useAuth } from "contexts/AuthContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";

export const SwitchOrganisationModal = () => {
  const [isSwitching, setIsSwitching] = useState(false);
  const { t } = useTranslation(["settings"]);
  const dialog = useDialog("switch-organisation");
  const { organisation } = useOrganisation();
  const { switchOrganisation, userData } = useAuth();

  const isCurrentOrg = userData?.organisation?.id === organisation?.id;

  async function onConfirm() {
    if (isSwitching || !organisation) {
      return;
    }

    setIsSwitching(true);

    try {
      await switchOrganisation(organisation);

      toast.success(t("settings:organisation.switch.success"));
      dialog.close();
    } catch (e) {
      toast.error(t("settings:organisation.switch.error"));
    }

    setIsSwitching(false);
  }

  return (
    <>
      <TooltipProvider>
        <Tooltip open={isCurrentOrg ? undefined : false}>
          <TooltipTrigger asChild>
            <span>
              <Button
                variant="tertiary"
                onClick={dialog.open}
                className="mx-4"
                icon="user-plus"
                disabled={isCurrentOrg}
              >
                {t("settings:organisation.switch.button")}
              </Button>
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t("settings:organisation.switch.tooltip")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Modal
        id={dialog.id!}
        open={dialog.isOpen}
        variant="warning"
        mainIconName="user-plus"
        title={t("settings:organisation.switch.title")}
        description={t("settings:organisation.switch.description", {
          name: organisation?.name,
        })}
        confirmLabel={t("settings:organisation.switch.actions.confirm")}
        cancelLabel={t("settings:organisation.switch.actions.cancel")}
        onOpenChange={dialog.onOpenChange}
        loading={isSwitching}
        onConfirm={() => onConfirm()}
      />
    </>
  );
};
