import { useTranslation } from "react-i18next";
import { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import { getKeywordsByType, IProduct } from "integrations/firebase/collections";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Input } from "components/ui/input";
import { toast } from "utils/toast";

interface InputModeProps {
  product: IProduct | undefined | null;
  setInputMode: (inputMode: boolean) => void;
  saveFinalKeywords: (keyword: string) => void;
}

const InputMode = ({
  product,
  setInputMode,
  saveFinalKeywords,
}: InputModeProps) => {
  const { t } = useTranslation(["general", "productDetails"]);
  const [newKeyword, setNewKeyword] = useState("");
  const { gaEvent } = useAnalytics();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;

    setNewKeyword(keyword);
  };

  const handleSaveNewKeyword = useCallback(
    async (keyword: string) => {
      if (!product) {
        return;
      }
      if (keyword) {
        if (
          getKeywordsByType(product).final.some(
            (k) => k.subjectHeadingText === keyword,
          )
        ) {
          toast.error(
            t("productDetails:finalKeywords.toasts.info.keywordAlreadyExists"),
          );

          gaEvent({
            type: "add_custom_keyword_to_final_selection",
            payload: {
              type: "exists_already",
              keyword,
              book: product.id,
            },
          });
          return;
        }
        saveFinalKeywords(keyword);
        gaEvent({
          type: "add_custom_keyword_to_final_selection",
          payload: {
            type: "confirm",
            book: product.id,
            keyword,
          },
        });
      }
      setInputMode(false);
      setNewKeyword("");
    },
    [setInputMode, product, saveFinalKeywords, t, gaEvent],
  );

  const handleKeypress = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (!product) {
        return;
      }
      if (event.key === "Enter") {
        await handleSaveNewKeyword(newKeyword);
      }
      if (event.key === "Escape") {
        gaEvent({
          type: "add_custom_keyword_to_final_selection",
          payload: {
            book: product.id,
            type: "cancel",
            keyword: newKeyword,
          },
        });
        setInputMode(false);
      }
    },
    [handleSaveNewKeyword, newKeyword, setInputMode, gaEvent, product],
  );

  const handleBlur = useCallback(async () => {
    await handleSaveNewKeyword(newKeyword);
  }, [newKeyword, handleSaveNewKeyword]);

  return (
    <div className="w-full min-h-14 flex items-center py-2 px-4 border-secondary-200 border">
      <Input
        placeholder={t("productDetails:finalKeywords.input.placeholder")}
        onChange={handleChange}
        onKeyDown={handleKeypress}
        onBlur={handleBlur}
        autoFocus
      />
    </div>
  );
};

export default InputMode;
