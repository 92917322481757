import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "components/ui/badge";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { FirestoreGenerationStatus } from "__generated__/models";
import { getLabels, getTooltipLabel, statusColors } from "./labels";

interface GenerationStatusBadgeProps {
  status?: FirestoreGenerationStatus;
  labelKey: "keywords" | "description" | "subjects" | "generating" | "error";
  errorMessage?: string;
}

export const GenerationStatusBadge = memo(
  ({
    status = FirestoreGenerationStatus.IMPORTED,
    labelKey,
    errorMessage,
  }: GenerationStatusBadgeProps) => {
    const { t } = useTranslation(["components"]);
    const colors = statusColors[status];

    const label = useMemo(() => {
      const labels = getLabels(t, colors.icon);

      return (
        labels.find((l) => l.key === status.toLowerCase()) ||
        labels.find((l) => l.key === labelKey)
      );
    }, [t, colors.icon, status, labelKey]);

    if (!label) {
      return null;
    }

    return (
      <Tooltip>
        <TooltipTrigger>
          <Badge
            data-testid={`badge-${labelKey}`}
            aria-label={`badge-${labelKey}-${status.toLowerCase()}`}
            className={`min-w-11 h-6 p-0 ${colors.bg} ${status === FirestoreGenerationStatus.REQUESTED || status === FirestoreGenerationStatus.GENERATING ? "animate-pulse-3" : ""}`}
          >
            {label.icon}
          </Badge>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          className="text-center shadow-tooltip text-neutral-600 whitespace-pre z-10 border-none leading-relaxed"
          sideOffset={6}
          data-testid="badge-tooltip"
        >
          <TooltipArrow
            className="fill-white drop-shadow-sm"
            width={12}
            height={6}
          />
          <p>{errorMessage ?? getTooltipLabel(t, label.label, status)}</p>
        </TooltipContent>
      </Tooltip>
    );
  },
);

GenerationStatusBadge.displayName = "GenerationStatusBadge";
