import { useTranslation } from "react-i18next";

import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { FilterItem, FilterType } from "./types";

export const useFilters = () => {
  const { t } = useTranslation(["books", "general"]);
  const { features } = useFeatureFlags();

  const filters: FilterItem[] = [
    {
      icon: "square-stack",
      label: t("books:filters.keywordCount"),
      type: FilterType.NUMERIC,
      name: "keywordCount",
      show: true,
    },
    {
      icon: "calendar",
      label: t("books:filters.publishedOn"),
      type: FilterType.DATE_RANGE,
      name: "publishedAt",
      show: features.publishedDateFilter,
    },
  ];

  return { filters };
};
