import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";
import { FirestoreProductSubject } from "__generated__/models";
import { SelectableListItem } from "components/List/SelectableListItem";
import { useSubjects } from "../../../hooks/useSubjects";

interface KeywordItemProps {
  keyword: FirestoreProductSubject;
  isSelected: boolean;
  testId: string;
}

export const KeywordItem = ({
  keyword,
  isSelected,
  testId,
}: KeywordItemProps) => {
  const { t } = useTranslation("products");
  const { handleAddSubject } = useSubjects();

  const handleClicked = useCallback(async () => {
    try {
      await handleAddSubject(keyword);
    } catch (e) {
      logError(e);
      toast.error(t("productDetails:errorToast.errorUpdate"));
    }
  }, [handleAddSubject, keyword, t]);

  if (!keyword.id || !keyword.subjectHeadingText) {
    return null;
  }

  return (
    <SelectableListItem
      id={keyword.id}
      text={keyword.subjectHeadingText}
      isSelected={isSelected}
      testId={testId}
      onSelect={handleClicked}
    />
  );
};
