import { FirestoreGenerationStatus } from "__generated__/models";
import { IProduct } from "integrations/firebase/collections";

const getDescriptionGenerationStatus = (product: IProduct) => {
  const actions = product?.actions?.descriptions?.actions;
  if (!actions) return FirestoreGenerationStatus.IMPORTED;

  const allGenerationStatuses = Object.values(actions)
    .map((action) => action?.generate?.status)
    .filter((status) => status !== undefined);

  if (allGenerationStatuses.length === 0) {
    return FirestoreGenerationStatus.IMPORTED;
  }
  // if one of them errored we show an error
  if (
    allGenerationStatuses.some(
      (status) => status === FirestoreGenerationStatus.ERROR,
    )
  ) {
    return FirestoreGenerationStatus.ERROR;
  }

  // if all of them are completed or cancelled we should show completed
  if (
    allGenerationStatuses.every(
      (status) =>
        status === FirestoreGenerationStatus.COMPLETED ||
        status === FirestoreGenerationStatus.CANCELLED,
    )
  ) {
    return FirestoreGenerationStatus.COMPLETED;
  }

  // if one of them is in a loading state (REQUESTED, GENERATING, GENERATED, PROCESSING) we should show that
  if (
    allGenerationStatuses.some(
      (status) =>
        status === FirestoreGenerationStatus.REQUESTED ||
        status === FirestoreGenerationStatus.GENERATING ||
        status === FirestoreGenerationStatus.GENERATED ||
        status === FirestoreGenerationStatus.PROCESSING,
    )
  ) {
    return FirestoreGenerationStatus.GENERATING;
  }

  return FirestoreGenerationStatus.IMPORTED;
};

export const getBadgeStatusFromRow = (product: IProduct) => {
  const descriptionStatus = getDescriptionGenerationStatus(product);
  return {
    subjectsStatus:
      product?.actions?.thema?.actions?.generate?.status ??
      FirestoreGenerationStatus.IMPORTED,
    keywordsStatus:
      product?.actions?.keywords?.actions?.generate?.status ??
      FirestoreGenerationStatus.IMPORTED,
    descriptionStatus,
  };
};
