import { useAuth } from "contexts/AuthContext";
import { query, where } from "firebase/firestore";
import {
  getProductsCollection,
  IProduct,
} from "integrations/firebase/collections";
import { useCollectionData } from "react-firebase-hooks/firestore";

export interface IGeneratingProducts {
  keywords: IProduct[];
  description: IProduct[];
}

export const useGeneratingWatcher = () => {
  const { userData } = useAuth();

  const [generatingProducts = []] = useCollectionData(
    userData?.organisation?.id
      ? query(
          getProductsCollection(),
          where("organisation.id", "==", userData?.organisation?.id),
          where("actions.active", "==", true),
        )
      : undefined,
  );

  return {
    generatingProducts,
  };
};
