import { useMemo } from "react";
import { IProduct } from "integrations/firebase/collections";
import { filterSubjects } from "../../utils";

export const useSubjectsData = (
  variant: "database" | "generated",
  product: IProduct | undefined,
) => {
  const databaseSubjects = useMemo(
    () => filterSubjects(product?.subjects?.database || []),
    [product],
  );
  const generatedSubjects = useMemo(
    () => filterSubjects(product?.subjects?.generated || []),
    [product],
  );
  const finalSubjects = useMemo(
    () => filterSubjects(product?.subjects?.final || []),
    [product],
  );

  const isEmptyList =
    variant === "database"
      ? !databaseSubjects.length
      : !generatedSubjects.length;
  const selectedSubjects =
    variant === "database" ? databaseSubjects : generatedSubjects;

  return {
    selectedSubjects,
    isEmptyList,
    finalSubjects,
  };
};
