import { Switch } from "components/ui/switch";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useTranslation } from "react-i18next";

interface AutofillSwitchProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export function AutofillSwitch({
  checked,
  onCheckedChange,
}: AutofillSwitchProps) {
  const { t } = useTranslation(["projectDetails"]);
  const { features } = useFeatureFlags();

  if (!features.rankKeywords) {
    return null;
  }

  return (
    <div className="flex items-center mt-6 bg-gray-50 p-4 rounded-lg">
      <div className="flex items-center space-x-3">
        <Switch
          id="autofill-final-list"
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
        <div>
          <div className="text-sm font-medium text-gray-900">
            {t("projectDetails:modals.autofillSwitch.title")}
          </div>
          <div className="text-sm text-gray-500">
            {t("projectDetails:modals.autofillSwitch.description")}
          </div>
        </div>
      </div>
    </div>
  );
}
