import { FirestoreProductSubject, SubjectSource } from "__generated__/models";
import { BaseItem } from "pages/ProductDetails/components/SortableList";
import { v4 } from "uuid";
import {
  qualifierTypes,
  subjectsTypes,
  SubjectSchemeIdentifier,
} from "./constants";

export const filterQualifiers = (qualifiers: FirestoreProductSubject[]) => {
  return qualifiers.filter((s) =>
    qualifierTypes.includes(
      s.subjectSchemeIdentifier as SubjectSchemeIdentifier,
    ),
  );
};

export const filterSubjects = (subjects: FirestoreProductSubject[]) => {
  return subjects.filter((s) =>
    subjectsTypes.includes(
      s.subjectSchemeIdentifier as SubjectSchemeIdentifier,
    ),
  );
};

export const createListItems = (items: FirestoreProductSubject[]): BaseItem[] =>
  items?.map((item) => ({
    id: item.id ?? "",
    value:
      item.subjectSchemeIdentifier === SubjectSchemeIdentifier.Keyword
        ? (item.subjectHeadingText ?? "")
        : (item.subjectCode ?? ""),
    description:
      item.subjectSchemeIdentifier === SubjectSchemeIdentifier.Keyword
        ? (item.subjectCode ?? "")
        : (item.subjectHeadingText ?? ""),
    infoText: item.rankingReasoning ?? "",
  }));

export const createManualSubject = (
  subject: string,
  identifier: SubjectSchemeIdentifier = SubjectSchemeIdentifier.Keyword,
): FirestoreProductSubject => {
  if (!subject?.trim()) {
    throw new Error("Subject cannot be empty");
  }
  return {
    id: v4(),
    subjectCode: null,
    subjectHeadingText: subject,
    subjectSchemeIdentifier: identifier,
    source: SubjectSource.MANUAL,
  };
};
