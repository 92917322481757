import { useProduct } from "contexts/ProductContext";
import { FinalThemaList } from "./components/FinalList/FinalThemaList";
import { ThemaTabs } from "./components/ThemaTabs";

export const ThemaTab = () => {
  const { product } = useProduct();

  if (!product) {
    return;
  }

  return (
    <div className="flex flex-row gap-4 h-full">
      <div className="flex-1 flex min-h-40">
        <ThemaTabs />
      </div>

      <div className="max-w-80 min-h-36">
        <FinalThemaList />
      </div>
    </div>
  );
};
