import { useCallback, useState } from "react";

import {
  IProduct,
  updateProduct,
  updateProductInTransaction,
} from "integrations/firebase/collections";
import { useAuth } from "contexts/AuthContext";
import { logError } from "shared/services/ErrorReporting";
import { useAnalytics } from "contexts/AnalyticsContext";
import { FirestoreError } from "firebase/firestore";
import { FirestoreProductSubject } from "__generated__/models";
import { DescriptionType } from "api/client/products";
import { useApi } from "./useApi";

export const useProductActions = () => {
  const { authUser } = useAuth();

  const api = useApi();

  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [errorGenerate, setErrorGenerate] = useState<FirestoreError>();
  const [errorUpdate, setErrorUpdate] = useState<Error>();

  const { gaEvent } = useAnalytics();

  const generateKeywords = useCallback(
    async (products: IProduct[], autofillFinalList?: boolean) => {
      if (!products || products.length === 0) {
        return;
      }

      setIsLoadingGenerate(true);
      setErrorGenerate(undefined);

      try {
        await api.products.generateKeywordsBulk(
          products.map((product) => product.id),
          autofillFinalList,
        );
        products.forEach((product) => {
          gaEvent({
            type: "bulk_generate_keywords",
            payload: {
              book: product.id,
              item_list_id: product.id,
              item_list_name: product.title,
              autofill_final_list: autofillFinalList,
            },
          });
        });
      } catch (err) {
        logError(err);
        setErrorGenerate(err as FirestoreError);
      }

      setIsLoadingGenerate(false);
    },
    [gaEvent, api],
  );

  const generateDescriptions = useCallback(
    async (products: IProduct[], descriptionTypes: DescriptionType[]) => {
      if (!products?.length) {
        return;
      }

      setIsLoadingGenerate(true);
      setErrorGenerate(undefined);

      try {
        await api.products.generateDescriptionsBulk(
          products.map((product) => product.id),
          descriptionTypes,
        );
        products.forEach((product) => {
          gaEvent({
            type: "bulk_generate_descriptions",
            payload: {
              book: product.id,
              item_list_id: product.id,
              item_list_name: product.title,
            },
          });
        });
      } catch (err) {
        logError(err);
        setErrorGenerate(err as FirestoreError);
      }

      setIsLoadingGenerate(false);
    },
    [gaEvent, api],
  );

  const updateProductById = useCallback(
    async (
      productId: string,
      getUpdateObject: (product?: IProduct) => any,
      inTransaction: boolean = false,
    ) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);

      try {
        if (inTransaction) {
          await updateProductInTransaction(productId, getUpdateObject);
        } else {
          await updateProduct(productId, getUpdateObject());
        }
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingUpdate(false);
    },
    [],
  );

  const generateProductKeywords = useCallback(
    async (product: IProduct, options?: { regenerate?: boolean }) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);

      try {
        const { id } = product;
        await api.products.generateKeywords(id);
        gaEvent({
          type: "generate_keywords",
          payload: {
            book: id,
            item_list_id: id,
            item_list_name: product?.title,
            regenerate: options?.regenerate,
          },
        });
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingUpdate(false);
    },
    [gaEvent, api],
  );

  const autofillProductKeywords = useCallback(
    async (product: IProduct) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);

      try {
        const { id } = product;
        await api.products.autofillKeywords(id);
        gaEvent({
          type: "autofill_keywords",
          payload: {
            book: id,
            item_list_id: id,
            item_list_name: product?.title,
          },
        });
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingUpdate(false);
    },
    [gaEvent, api],
  );

  const generateProductDescription = useCallback(
    async (
      product: IProduct,
      hash: string,
      targetGroup: string | null,
      specialRequest: string | null,
      options?: { regenerate?: boolean },
    ) => {
      setIsLoadingGenerate(true);
      setErrorUpdate(undefined);

      try {
        await api.products.generateDescription(product.id, {
          hash,
          targetGroup,
          specialRequest,
        });
        gaEvent({
          type: "generate_description",
          payload: {
            book: product.id,
            item_list_id: product.id,
            item_list_name: product?.title,
            regenerate: options?.regenerate,
            target: targetGroup,
            details: specialRequest,
          },
        });
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingGenerate(false);
    },
    [api, gaEvent],
  );

  const generateProductSubjects = useCallback(
    async (productId: string, options?: { regenerate?: boolean }) => {
      setIsLoadingGenerate(true);
      setErrorUpdate(undefined);

      try {
        await api.products.generateThema(productId);
        gaEvent({
          type: "generate_subjects",
          payload: {
            book: productId,
            regenerate: options?.regenerate,
          },
        });
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingGenerate(false);
    },
    [api, gaEvent],
  );

  const saveFinalSubjects = useCallback(
    async (productId: string, subjects: FirestoreProductSubject[]) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);

      try {
        await updateProductById(productId, () => ({
          "subjects.final": subjects,
        }));
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }

      setIsLoadingUpdate(false);
    },
    [updateProductById],
  );

  const setProductDescription = useCallback(
    async (productId: string, descriptionHash: string, text: string) => {
      await updateProductById(
        productId,
        (product) => {
          if (!product) {
            throw new Error("Unable to find product to update");
          }

          if (!product.descriptions?.length) {
            throw new Error("Unable to find product description to update");
          }

          const descriptions = product.descriptions.map((description) => {
            if (description.hash === descriptionHash) {
              return {
                ...description,
                textGenerated: text,
              };
            }

            return description;
          });

          const generatedDescriptionDataPath = "generated.description.data";

          return {
            [`${generatedDescriptionDataPath}.text`]: text,
            [`${generatedDescriptionDataPath}.userId`]: authUser?.uid,
            [`${generatedDescriptionDataPath}.isManualInput`]: true,
            descriptions,
          };
        },
        true,
      );
    },
    [updateProductById, authUser?.uid],
  );

  return {
    isLoadingGenerate,
    errorGenerate,
    generateKeywords,
    generateDescriptions,
    isLoadingUpdate,
    errorUpdate,
    generateProductKeywords,
    autofillProductKeywords,
    generateProductDescription,
    setProductDescription,
    generateProductSubjects,
    saveFinalSubjects,
  };
};
