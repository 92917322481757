export enum SubjectSchemeIdentifier {
  ThemaSubjectCategory = "themaSubjectCategory",
  ThemaPlaceQualifier = "themaPlaceQualifier",
  ThemaTimePeriodQualifier = "themaTimePeriodQualifier",
  ThemaStyleQualifier = "themaStyleQualifier",
  ThemaEducationalPurposeQualifier = "themaEducationalPurposeQualifier",
  ThemaInterestAgeSpecialInterestQualifier = "themaInterestAgeSpecialInterestQualifier",
  Keyword = "keyword",
}

export const qualifierTypes = [
  SubjectSchemeIdentifier.ThemaPlaceQualifier,
  SubjectSchemeIdentifier.ThemaTimePeriodQualifier,
  SubjectSchemeIdentifier.ThemaStyleQualifier,
  SubjectSchemeIdentifier.ThemaEducationalPurposeQualifier,
  SubjectSchemeIdentifier.ThemaInterestAgeSpecialInterestQualifier,
];

export const subjectsTypes = [SubjectSchemeIdentifier.ThemaSubjectCategory];
