import { TFunction } from "i18next";
import { FileText, ListTree, LoaderCircle, SquareStack } from "lucide-react";
import { FirestoreGenerationStatus } from "__generated__/models";

export type LabelKey =
  | "keywords"
  | "description"
  | "subjects"
  | "generating"
  | "requested"
  | "error";

interface Label {
  key: LabelKey;
  label: string;
  icon: JSX.Element;
}

export const getTooltipLabel = (
  t: TFunction,
  label: string,
  status: FirestoreGenerationStatus,
) => {
  switch (status) {
    case FirestoreGenerationStatus.IMPORTED:
      return t("components:generationStatusBadge.tooltips.imported", {
        label,
      });
    case FirestoreGenerationStatus.GENERATING:
      return t("components:generationStatusBadge.tooltips.generating", {
        label,
      });
    case FirestoreGenerationStatus.COMPLETED:
      return t("components:generationStatusBadge.tooltips.completed", {
        label,
      });
    case FirestoreGenerationStatus.ERROR:
      return t("components:generationStatusBadge.tooltips.error", {
        label,
      });
    case FirestoreGenerationStatus.REQUESTED:
      return t("components:generationStatusBadge.tooltips.generating", {
        label,
      });
    default:
      return t("components:generationStatusBadge.tooltips.default", {
        label,
      });
  }
};
export const getLabels: (t: TFunction, color: string) => Label[] = (
  t,
  color,
) => [
  {
    key: "keywords",
    label: t("components:generationStatusBadge.labels.keywords"),
    icon: <SquareStack className="w-3 h-3" color={color} />,
  },
  {
    key: "description",
    label: t("components:generationStatusBadge.labels.description"),
    icon: <FileText className="w-3 h-3" color={color} />,
  },
  {
    key: "subjects",
    label: t("components:generationStatusBadge.labels.subjects"),
    icon: <ListTree className="w-3 h-3" color={color} />,
  },
  {
    key: "generating",
    label: t("components:generationStatusBadge.labels.generating"),
    icon: (
      <LoaderCircle
        data-testid="badge-generating-icon"
        className="w-3 h-3 animate-spin"
      />
    ),
  },
  {
    key: "requested",
    label: t("components:generationStatusBadge.labels.generating"),
    icon: (
      <LoaderCircle
        data-testid="badge-generating-icon"
        className="w-3 h-3 animate-spin"
      />
    ),
  },
];

export const statusColors: Record<
  FirestoreGenerationStatus,
  { bg: string; icon: string }
> = {
  [FirestoreGenerationStatus.IMPORTED]: {
    bg: "bg-secondary-25",
    icon: "#A1A1AA", // bg-secondary-400
  },
  [FirestoreGenerationStatus.CANCELLED]: {
    bg: "bg-secondary-25",
    icon: "#A1A1AA", // bg-secondary-400
  },
  [FirestoreGenerationStatus.COMPLETED]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGenerationStatus.GENERATING]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGenerationStatus.GENERATED]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGenerationStatus.PROCESSING]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGenerationStatus.REQUESTED]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGenerationStatus.ERROR]: {
    bg: "bg-icon-error-background",
    icon: "#DC2626", // bg-system-error
  },
};
