import { Client } from "@hey-api/client-fetch";
import {
  getEvaluation,
  postEvaluation,
  getEvaluationResponseSchema,
  postEvaluationResponseSchema,
} from "__generated__/api";
import { parseResult } from "../parseHelper";

export class EvaluationApi {
  constructor(private client: Client) {}

  async evaluate(isbns: string[] | "ALL") {
    const response = await postEvaluation({
      body: { requestedProducts: isbns },
      client: this.client,
    });

    const result = parseResult(postEvaluationResponseSchema, response, {});

    return result?.data;
  }

  async getEvaluation(id: string) {
    const response = await getEvaluation({
      path: { identifier: id },
      client: this.client,
    });

    const result = parseResult(getEvaluationResponseSchema, response, {});

    return result?.data;
  }
}
