import { Controller, Control } from "react-hook-form";
import { Label } from "components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectPlaceholder,
  SelectTrigger,
} from "components/ui/select";
import { TicketCategory } from "integrations/zendesk";
import { useTranslation } from "react-i18next";
import { categoryTranslation, SignUpSchemaType } from "../utils";

interface CategorySelectProps {
  control: Control<SignUpSchemaType>;
}

export const CategorySelect = ({ control }: CategorySelectProps) => {
  const { t } = useTranslation(["contactUs"]);
  return (
    <div className="space-y-2">
      <Label className="text-s">{t("contactUs:form.category.label")}</Label>
      <Controller
        name="category"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select onValueChange={field.onChange} value={field.value}>
            <SelectTrigger
              aria-label="category"
              className="w-full border-secondary-200 bg-white rounded-lg h-12"
            >
              <SelectPlaceholder
                placeholder={t("contactUs:form.category.select.placeholder")}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {Object.entries(TicketCategory).map(([key, value]) => (
                  <SelectItem
                    aria-label={key}
                    key={key}
                    value={value}
                    className="text-s"
                  >
                    {categoryTranslation(t)[value]}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
      />
    </div>
  );
};
