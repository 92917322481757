import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { TruncatedText } from "components/TruncatedText";
import { Badge } from "../../../../../../components/ui/badge";

export interface UploadReportProduct {
  isbn: string;
  title?: string;
  author?: string[];
  productGroupDescription?: string;
  failed?: boolean;
  publishedAt?: Date | null;
}

interface OrganisationOnixUploadFileReportDialogTableProps {
  loading?: boolean;
  products: UploadReportProduct[];
}

export const OrganisationOnixUploadFileReportDialogTable = ({
  loading,
  products,
}: OrganisationOnixUploadFileReportDialogTableProps) => {
  const { t } = useTranslation(["settings"]);

  const columnWidths = {
    isbn: "15%",
    author: "15%",
    title: "25%",
    productForm: "15%",
    publishedOn: "15%",
    qualityCheck: "15%",
  };

  return (
    <div
      className="relative w-full rounded-xl border border-secondary-200 text-s h-[48vh] overflow-hidden"
      role="region"
    >
      <table
        className="w-full caption-bottom text-sm text-secondary-600 table-fixed"
        role="table"
      >
        <colgroup>
          <col style={{ width: columnWidths.isbn }} />
          <col style={{ width: columnWidths.author }} />
          <col style={{ width: columnWidths.title }} />
          <col style={{ width: columnWidths.productForm }} />
          <col style={{ width: columnWidths.publishedOn }} />
          <col style={{ width: columnWidths.qualityCheck }} />
        </colgroup>
        <TableHeader>
          <TableRow>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.isbn")}
            </TableHead>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.author")}
            </TableHead>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.title")}
            </TableHead>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.productForm")}
            </TableHead>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.publishedOn")}
            </TableHead>
            <TableHead className="truncate">
              {t("settings:organisation.onix.table.qualityCheck")}
            </TableHead>
          </TableRow>
        </TableHeader>
      </table>
      <div className="overflow-y-auto" style={{ height: "calc(48vh - 40px)" }}>
        <table className="w-full caption-bottom text-sm text-secondary-600 table-fixed">
          <colgroup>
            <col style={{ width: columnWidths.isbn }} />
            <col style={{ width: columnWidths.author }} />
            <col style={{ width: columnWidths.title }} />
            <col style={{ width: columnWidths.productForm }} />
            <col style={{ width: columnWidths.publishedOn }} />
            <col style={{ width: columnWidths.qualityCheck }} />
          </colgroup>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="flex items-center justify-center w-full pt-10">
                    <LoadingSpinner size="100px" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              products.map((product) => (
                <TableRow key={product.isbn}>
                  <TableCell>
                    <TruncatedText text={product.isbn} />
                  </TableCell>
                  <TableCell>
                    <TruncatedText
                      text={
                        Array.isArray(product.author)
                          ? product.author.join(", ")
                          : product.author
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TruncatedText text={product.title} />
                  </TableCell>
                  <TableCell>
                    <TruncatedText text={product.productGroupDescription} />
                  </TableCell>
                  <TableCell>
                    <TruncatedText
                      text={
                        product.publishedAt
                          ? format(new Date(product.publishedAt), "dd/MM/yyyy")
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {product.failed ? (
                      <Badge variant="error" className="ml-2">
                        {t("settings:organisation.onix.misc.badge.failed")}
                      </Badge>
                    ) : (
                      <Badge variant="success" className="ml-2">
                        {t("settings:organisation.onix.misc.badge.success")}
                      </Badge>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </table>
      </div>
    </div>
  );
};
