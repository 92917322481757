import { useFormContext } from "react-hook-form";
import { IconInput } from "components/input/IconInput";
import { Calendar } from "components/ui/calendar";
import { getFormattedDate } from "hooks/search/utils";
import { transformToInputDate } from "../utils";

export const DateRangeFilter = ({ name }: { name: string }) => {
  const { setValue, watch } = useFormContext();
  const dateRange = watch(`filters.${name}.range`);

  const handleDateChange = (range: { from?: Date; to?: Date } | undefined) => {
    if (!range) return;
    const updatedRange = {
      from: range.from ? transformToInputDate(range.from) : undefined,
      to: range.to ? transformToInputDate(range.to) : undefined,
    };
    setValue(`filters.${name}.range`, updatedRange);
  };

  return (
    <div className="flex items-center flex-col gap-2 p-2">
      <IconInput
        type="date"
        iconName="calendar"
        aria-label="from date input"
        className="w-full"
        value={getFormattedDate(dateRange?.from)}
        onChange={(e) => {
          const date = new Date(e.target.value);
          if (Number.isNaN(date.getTime())) return;
          handleDateChange({
            ...dateRange,
            from: date,
          });
        }}
      />
      <IconInput
        iconName="calendar"
        type="date"
        className="w-full"
        value={getFormattedDate(dateRange?.to)}
        aria-label="to date input"
        onChange={(e) => {
          const date = new Date(e.target.value);
          if (Number.isNaN(date.getTime())) return;
          handleDateChange({
            ...dateRange,
            to: date,
          });
        }}
      />
      <Calendar mode="range" selected={dateRange} onSelect={handleDateChange} />
    </div>
  );
};
