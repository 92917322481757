import { useAuth } from "contexts/AuthContext";
import { Badge } from "components/ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { useTranslation } from "react-i18next";

export const CompanyBadge = () => {
  const { userData } = useAuth();
  const { t } = useTranslation("settings");
  const organisationName = userData?.organisation?.name;

  if (!organisationName) {
    return null;
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          variant="secondary"
          className="cursor-default"
          data-testid="company-badge"
        >
          {organisationName}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>
        <p className="text-xs">{t("organisation.tooltip")}</p>
      </TooltipContent>
    </Tooltip>
  );
};
