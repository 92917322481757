import React from "react";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "components/InfoTooltip";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";

export interface DetailsGenerateBookDescriptionProps {
  specialDetails: string | null;
  setSpecialDetails: (specialDetails: string | null) => void;
}

export const AdditionalInfo: React.FC<DetailsGenerateBookDescriptionProps> = ({
  specialDetails,
  setSpecialDetails,
}) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <div className="grid gap-3">
      <Label className="flex items-center gap-2 text-s">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.special",
        )}

        <InfoTooltip
          text={t(
            "productDetails:bookDescription.tabGenerate.detailsGenerate.specialTooltip",
          )}
          contentClassName="font-light text-center px-5"
        />
      </Label>

      <Textarea
        rows={7}
        className="placeholder:text-secondary-400 placeholder:text-s text-s"
        value={specialDetails || ""}
        placeholder={t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.specialPlaceholder",
        )}
        role="textbox"
        onChange={(event) => setSpecialDetails(event.target.value)}
        data-testid="special-details-textfield"
      />
    </div>
  );
};
