import { useTranslation } from "react-i18next";
import { BasePanel } from "components/BasePanel";
import { KeywordItem } from "../KeywordItem";
import { getKeywordsByType } from "../../../../../../integrations/firebase/collections";
import { useProduct } from "../../../../../../contexts/ProductContext";

export const DatabaseKeywords = () => {
  const { t } = useTranslation(["general", "productDetails"]);

  const { product } = useProduct();
  const { database: keywords, final: finalKeywords } =
    getKeywordsByType(product);

  return (
    <BasePanel
      title={t("productDetails:databaseKeywords.header")}
      isEmpty={!keywords.length}
      emptyProps={{
        title: t("productDetails:databaseKeywordsEmpty.title"),
        description: t("productDetails:databaseKeywordsEmpty.description"),
      }}
    >
      {keywords.map((item) => (
        <KeywordItem
          key={item.id}
          keyword={item}
          testId={`database-keyword-${item.id}`}
          isSelected={finalKeywords
            .map((keyword) => keyword.id)
            .includes(item.id)}
        />
      ))}
    </BasePanel>
  );
};
