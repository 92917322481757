import React, { useEffect, useMemo } from "react";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "components/button";
import { RoutePath } from "shared/constants";
import { TabHeader } from "components/TabHeader";
import { format } from "date-fns";
import { Icon } from "components/icon/Icon";
import { useProjectDetails } from "./contexts/ProjectDetailsContext";
import { getProjectProducts } from "./utils";
import { ErrorMessage } from "./components/ErrorMessage";
import { DetailsTable } from "./table/DetailsTable";
import { ProjectEditISBNDialog } from "./components/ProjectEditISBNDialog";

export const ProjectDetails: React.FC = () => {
  const {
    setProductsToExport,
    onRowClickCallback,
    isLoadingRead,
    errorRead,
    products,
    isLoadingProducts,
    project,
    updateProjectName,
    errorProjectRead,
  } = useProjectDetails();

  const { t } = useTranslation(["projectDetails"]);
  const navigate = useNavigate();

  const isLoading = useMemo(
    () => isLoadingRead || isLoadingProducts,
    [isLoadingProducts, isLoadingRead],
  );

  const changeProjectName = (name: string) => {
    if (project?.id) {
      updateProjectName(project?.id, name);
    }
  };

  useEffect(() => {
    if (isLoading || !project || !products) {
      return;
    }
    const projectProducts = getProjectProducts(project, products) as IProduct[];

    setProductsToExport(
      projectProducts.filter((p) => p.isbn !== null) as IProduct[],
    );

    return () => {
      setProductsToExport([]);
    };
  }, [isLoading, project, products, setProductsToExport]);

  if ((errorRead || errorProjectRead) && !isLoading) {
    return <ErrorMessage backLink={RoutePath.Projects} />;
  }

  const created = project?.createdAt
    ? format(project?.createdAt, "MMM dd, yyyy")
    : undefined;

  return (
    <div className="space-y-6">
      <div className="flex flex-row justify-between items-start gap-2">
        <div>
          <Button
            variant="ghost"
            label={t("projectDetails:buttons.projects")}
            icon="arrow-left"
            testId="button-projects-back"
            className="-ml-4"
            onClick={() => navigate(RoutePath.Projects)}
          />

          <TabHeader
            title={project?.name || ""}
            onEdit={changeProjectName}
            subtitle={
              <div className="flex items-center">
                <Icon name="calendar" size="small" iconClassName="mr-1.5" />
                <div>{created}</div>
              </div>
            }
            isEditable
          />
        </div>

        <div className="flex flex-start">
          <ProjectEditISBNDialog />
        </div>
      </div>

      <DetailsTable
        onRowClick={onRowClickCallback}
        isLoading={isLoading}
        products={products || []}
      />
    </div>
  );
};
