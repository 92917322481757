import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "components/errors/ErrorPage";

export const NotFound: React.FC = () => {
  const { t } = useTranslation(["notFound"]);

  return (
    <ErrorPage title={t("notFound:title")} message={t("notFound:message")} />
  );
};
