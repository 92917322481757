import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import escapeRegExp from "lodash/escapeRegExp";

export interface IKeywords {
  database?: string[];
  generated?: string[];
}

export interface KeywordsOptions {
  keywords?: IKeywords;
  text: {
    database: string;
    generated: string;
  };
}

interface Keyword {
  type: string;
  from: number;
  to: number;
}

export class KeywordsCore {
  constructor(
    protected doc: ProseMirrorNode,
    public keywords?: IKeywords,
  ) {}

  private results: Array<Keyword> = [];

  record(type: string, from: number, to: number) {
    this.results.push({
      type,
      from,
      to,
    });
  }

  getResults() {
    return this.results;
  }

  scan() {
    this.doc.descendants((node, position) => {
      if (!node.isText) {
        return;
      }

      const { text } = node;

      if (!text) {
        return;
      }

      const processKeyword = (
        type: "database" | "generated",
        keyword: string,
      ) => {
        const reg = new RegExp(escapeRegExp(keyword.toLowerCase()), "g");

        let matches: RegExpExecArray | null;

        do {
          matches = reg.exec(text.toLowerCase());

          if (!matches) {
            // eslint-disable-next-line no-continue
            continue;
          }

          this.record(
            `${type}-keyword`,
            position + matches.index,
            position + matches.index + matches[0].length,
          );
        } while (matches);
      };

      this.keywords?.database?.forEach((keyword) =>
        processKeyword("database", keyword),
      );

      this.keywords?.generated?.forEach((keyword) =>
        processKeyword("generated", keyword),
      );
    });

    return this;
  }
}

function runKeywordMarker(doc: ProseMirrorNode, keywords?: IKeywords) {
  const plugin = new KeywordsCore(doc, keywords);

  const results = plugin.scan().getResults();

  const decorations: [any?] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const result of results) {
    decorations.push(
      Decoration.inline(result.from, result.to, {
        class: result.type,
      }),
    );
  }

  return DecorationSet.create(doc, decorations);
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    keywords: {
      setKeywords: (keywords?: IKeywords) => ReturnType;
    };
  }
}

const key = new PluginKey("keywords");

const Keywords = Extension.create<KeywordsOptions>({
  name: "keywords",

  addOptions() {
    return {
      keywords: {
        database: [],
        generated: [],
      },
      text: {
        database: "Database Keyword",
        generated: "Generated Keyword",
      },
    };
  },

  addCommands() {
    return {
      setKeywords:
        (keywords?: IKeywords) =>
        ({ dispatch, tr }) => {
          this.options.keywords = keywords;

          const decorations = runKeywordMarker(tr.doc, keywords);

          if (dispatch) {
            dispatch(tr.setMeta(key, { decorations }));
          }

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    const tooltip = document.createElement("div");

    tooltip.classList.add("generated-keyword-tooltip");
    tooltip.innerText = this.options.text.generated;
    tooltip.style.opacity = "0";
    tooltip.style.display = "none";
    tooltip.dataset.testid = "generated-keyword-tooltip";

    this.editor.view.dom.parentNode?.appendChild(tooltip);

    return [
      new Plugin({
        // view: (view) => new SelectionSizeTooltip(view),
        key,
        state: {
          init: (_, { doc }) => {
            // Mark the keywords when the editor is initialized
            return runKeywordMarker(doc, this.options.keywords);
          },
          apply: (transaction, oldState) => {
            // If the doc has changed, mark the keywords again
            if (transaction.docChanged) {
              return runKeywordMarker(transaction.doc, this.options.keywords);
            }

            // If the extension has been updated, apply the new decorations
            const meta = transaction.getMeta(key);

            if (meta && meta.decorations) {
              return meta.decorations;
            }

            return oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
          handleDOMEvents: {
            mouseleave: () => {
              tooltip.style.opacity = "0";
              tooltip.style.display = "none";
            },
            mouseover: (view, event) => {
              const target = event.target as HTMLElement;

              tooltip.style.display = "block";

              if (!target.className.includes("generated-keyword")) {
                tooltip.style.opacity = "0";
                tooltip.style.display = "none";
                return;
              }

              const rect = target.getBoundingClientRect();
              const { parentElement } = this.editor.view.dom;
              const offsetTop = parentElement?.scrollTop || 0;
              const tooltipRect = tooltip.getBoundingClientRect();

              const top =
                view.dom.offsetTop + target.offsetTop - offsetTop - 26;

              const left =
                view.dom.offsetLeft +
                target.offsetLeft -
                tooltipRect.width / 2 +
                rect.width / 2;

              tooltip.style.top = `${Math.min(top, window.innerHeight - tooltipRect.height - offsetTop)}px`;
              tooltip.style.left = `${Math.min(left, window.innerWidth - tooltipRect.width)}px`;
              tooltip.style.opacity = "1";
            },
          },
        },
      }),
    ];
  },
});

export default Keywords;
