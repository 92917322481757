import { FirestoreGenerationStatus } from "reedy-data/models";

/**
 * The maximum amount of books that can be selected
 * for generating metadata
 */
export const GENERATE_METADATA_BOOKS_LIMIT = 50;

/**
 * The maximum amount of books that can be selected,
 * when prepared to be saved as a project
 */
export const SAVE_AS_PROJECT_BOOKS_LIMIT = 250;

/**
 * The amount of books that can be selected for project creation,
 * before we trigger a warning dialog, explaining that the
 * project might be hard to manage.
 */
export const SAVE_AS_PROJECT_BOOKS_WARNING_LIMIT = 100;

/**
 * The amount of books that can be updated at once
 * in the generateKeywords and generateDescriptions functions
 * 500 is the firebase limit, 250 is our current limit for items in a project anyways
 */
export const BATCH_SIZE: number = 500;

/**
 * The maximum amount of characters that should be used in the keywords field
 * according to amazon's requirements
 */
export const MAX_KEYWORDS_CHARACTERS_COUNT = 500;

/**
 * The current version of our terms and conditions
 */
export const TERMS_AND_CONDITIONS_VERSION = "1.0.0";

/**
 * The FirestoreGenerationStatus that are considered completed
 */
export const COMPLETED_STATUSES: FirestoreGenerationStatus[] = [
  FirestoreGenerationStatus.COMPLETED,
  FirestoreGenerationStatus.ERROR,
  FirestoreGenerationStatus.CANCELLED,
];
