import { BookDescriptionProvider } from "contexts/BookDescriptionContext";
import { MulticolumnBasePanel } from "components/BasePanel/MulticolumnBasePanel";
import { DatabaseDescription } from "./components/DatabaseDescription/DatabaseDescription";
import { GenerateDescription } from "./components/GenerateDescription/GenerateDescription";
import { DescriptionsList } from "./components/DescriptionsList";

export const BookDescriptionTab = () => {
  return (
    <BookDescriptionProvider>
      <MulticolumnBasePanel
        gridTemplateColumns="300px 1fr 1fr"
        columns={[
          {
            component: <DescriptionsList />,
          },
          {
            component: <DatabaseDescription />,
          },
          {
            component: <GenerateDescription />,
          },
        ]}
      />
    </BookDescriptionProvider>
  );
};
