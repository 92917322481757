import { Icon } from "components/icon/Icon";
import {
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "components/ui/dropdown-menu";
import { FilterItem as FilterItemProps } from "hooks/search/types";
import { ActiveFilterBadge } from "./ActiveFilterBadge";

import { FilterTypeSelector } from "./FilterTypeSelector";

export const FilterItem = ({ icon, label, type, name }: FilterItemProps) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="h-12 min-w-60" hideChevron>
        <div className="flex flex-row w-full justify-between group pl-2">
          <div className="flex flex-row gap-3 items-center">
            <Icon className="w-5 h-5" name={icon} />
            {label}
          </div>
          <ActiveFilterBadge filterName={name} />
        </div>
      </DropdownMenuSubTrigger>
      <DropdownMenuSubContent>
        <FilterTypeSelector type={type} name={name} />
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
};
