const c = (i, n) => {
  var e, s;
  if (i)
    return n ? (s = i.descriptions) == null ? void 0 : s.find((t) => t.hash === n) : (e = i.descriptions) == null ? void 0 : e.find((t) => t.default);
}, r = (i) => {
  var n;
  return (n = i == null ? void 0 : i.descriptions) == null ? void 0 : n.find((e) => e.default);
}, f = (i) => {
  var n, e;
  return (e = (n = i == null ? void 0 : i.actions) == null ? void 0 : n.descriptions) == null ? void 0 : e.actions;
}, o = (i, n) => {
  var e, s, t;
  if (!(!i || !n))
    return (t = (s = (e = i.actions) == null ? void 0 : e.descriptions) == null ? void 0 : s.actions) == null ? void 0 : t[n];
}, a = (i) => {
  var n;
  return o(i, (n = r(i)) == null ? void 0 : n.hash);
};
export {
  f as getAllDescriptionActions,
  r as getDefaultDescription,
  a as getDefaultDescriptionActions,
  c as getDescription,
  o as getDescriptionActions
};
