import { cn } from "utils";
import { Icon } from "./Icon";

export const Info = ({
  className,
  pulse = true,
}: {
  className?: string;
  pulse?: boolean;
}) => {
  return (
    <div className="relative flex justify-center items-center w-full pb-3">
      <div className="relative h-16">
        <span className="absolute flex h-10 w-10 top-1/3 -left-[20px] items-center justify-center">
          {pulse && (
            <span
              className={cn(
                "animate-ping duration-2000 absolute inline-flex h-full w-full rounded-full bg-primary-700 opacity-15",
                className,
              )}
            />
          )}
          <span
            className={cn(
              "absolute inline-flex rounded-full h-10 w-10 bg-primary-400 opacity-15",
              className,
            )}
          />
          <Icon
            color="rgb(0, 78, 235)"
            className="absolute"
            name="zap"
            size="large"
          />
        </span>
      </div>
    </div>
  );
};
