import { collection, CollectionReference } from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import {
  FirestoreGenerationRequestDescriptions,
  FirestoreGenerationStatus as GenerationStatus,
} from "__generated__/models";
import { converter } from "../utils";

export { GenerationStatus };

export const getGenerationRequestsDescriptionsCollection = () =>
  collection(db, "generationRequestsDescriptions").withConverter(
    converter,
  ) as CollectionReference<FirestoreGenerationRequestDescriptions>;
