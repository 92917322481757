import * as React from "react";
import { cn } from "utils";
import { BasePanel, BasePanelProps } from "./BasePanel";

type ColumnConfig =
  | { component: React.ReactNode; key?: string }
  | Omit<BasePanelProps, "className">;

interface MulticolumnBasePanelProps {
  columns: ColumnConfig[];
  className?: string;
  gridTemplateColumns?: string;
}

export const MulticolumnBasePanel: React.FC<MulticolumnBasePanelProps> = ({
  columns,
  className,
  gridTemplateColumns = `repeat(${columns.length}, 1fr)`,
}) => {
  return (
    <div className="h-full w-full">
      <div
        className={cn("grid min-w-[1000px] h-full", className)}
        style={{ gridTemplateColumns }}
      >
        {columns.map((column, index) => {
          const isFirst = index === 0;
          const isLast = index === columns.length - 1;
          const columnClassName = cn(
            "border border-secondary-200",
            isFirst && "rounded-l-xl rounded-r-none border-r-0",
            !isFirst && !isLast && "rounded-none border-r-0",
            isLast && "rounded-r-xl rounded-l-none",
          );

          if ("component" in column) {
            return (
              <div
                key={column.key || `column-${index}`}
                className={columnClassName}
              >
                {column.component}
              </div>
            );
          }

          return (
            <BasePanel
              key={column.title}
              {...column}
              className={columnClassName}
            />
          );
        })}
      </div>
    </div>
  );
};

MulticolumnBasePanel.displayName = "MulticolumnBasePanel";
