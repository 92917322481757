import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { SkeletonDescription } from "components/skeletons/SkeletonDescription";
import { Generate } from "components/svg/icons/Generate";
import * as React from "react";

interface GenerateBookDescriptionEmptyProps {
  onClick(): void;
  disabled?: boolean;
}

export const GenerateBookDescriptionEmpty = ({
  onClick,
  disabled,
}: GenerateBookDescriptionEmptyProps) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <div className="flex flex-col items-center space-y-6 py-40 w-full">
      <div className="relative mt-4">
        <SkeletonDescription
          animated={false}
          className="scale-90 -top-6 absolute -z-10"
        />
        <SkeletonDescription
          animated={false}
          className="scale-95 -top-3 absolute -z-10"
        />
        <SkeletonDescription animated={false} variant="primary" />
      </div>

      <div className="text-center space-y-2">
        <p className="text-secondary-900">
          {t("productDetails:bookDescription.tabGenerate.empty.header")}
        </p>

        {disabled ? (
          <p className="text-s text-red-400">
            {t("productDetails:bookDescription.tabGenerate.empty.disabled")}
          </p>
        ) : (
          <p className="text-s text-secondary-500">
            {t("productDetails:bookDescription.tabGenerate.empty.message")}
          </p>
        )}
      </div>

      <Button
        variant="primary"
        label={
          <div className="flex flex-row items-center">
            <Generate className="w-4 h-4 mr-2" size={14} />
            <p className="text-s">
              {t("productDetails:bookDescription.tabGenerate.actions.generate")}
            </p>
          </div>
        }
        disabled={disabled}
        onClick={onClick}
        size="small"
        analyticsId="button_generate_book_description"
        testId="button-open-generate-card"
      />
    </div>
  );
};
