import { useGeneratingWatcher } from "hooks/useGeneratingWatcher";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { IProduct } from "../integrations/firebase/collections";

interface ICurrentProductContext {
  currentProductDescriptionWordsCount: number;
  setCurrentProductDescriptionWordsCount: (value: number) => void;
  generatingProducts: IProduct[];
}

export const CurrentProductContext = createContext<ICurrentProductContext>(
  undefined as never,
);

export const useCurrentProduct = () => useContext(CurrentProductContext);

export const CurrentProductContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { generatingProducts } = useGeneratingWatcher();
  const [
    currentProductDescriptionWordsCount,
    setCurrentProductDescriptionWordsCount,
  ] = useState(0);

  const currentProductProviderValue = useMemo(
    () => ({
      currentProductDescriptionWordsCount,
      setCurrentProductDescriptionWordsCount,
      generatingProducts,
    }),
    [
      currentProductDescriptionWordsCount,
      setCurrentProductDescriptionWordsCount,
      generatingProducts,
    ],
  );
  return (
    <CurrentProductContext.Provider value={currentProductProviderValue}>
      {children}
    </CurrentProductContext.Provider>
  );
};
