import { FirestoreProductDescription } from "reedy-data/models";

/**
 * The supported text types for descriptions
 */
export const supportedDescriptions: {
  textTypes: string[];
  audiences: string[];
} = {
  textTypes: ["Description"],
  audiences: [
    "booktrade",
    "end_customers",
    "end_customer",
    "end-customers",
    "end-customer",
    "unrestricted",
  ],
};
export const filterDescriptions = (
  descriptions: FirestoreProductDescription[],
) =>
  descriptions
    .filter(
      (desc) =>
        !desc.textType ||
        supportedDescriptions.textTypes.includes(desc.textType),
    )
    .filter((desc) =>
      desc.audiences?.some((audience) =>
        supportedDescriptions.audiences.includes(audience.toLowerCase()),
      ),
    );
