import { useTranslation } from "react-i18next";
import { Input } from "components/ui/input";
import { useFormContext } from "react-hook-form";
import { ComparisonOperatorDropdown } from "./ComparisonOperatorDropdown";

interface NumericFilterProps {
  error: string | undefined;
  name: string;
}

export const NumericFilter = ({ error, name }: NumericFilterProps) => {
  const { t } = useTranslation(["general"]);
  const { register } = useFormContext();
  return (
    <span className="flex flex-row">
      <ComparisonOperatorDropdown name={`filters.${name}.comparisonOperator`} />

      <Input
        type="number"
        min={0}
        placeholder={t("general:filters.numericPlaceholder")}
        className="border-l-0 rounded-l-none text-sm w-20 text-left"
        aria-label={`${name} input`}
        aria-invalid={!!error}
        {...register(`filters.${name}.value`, { valueAsNumber: true })}
      />
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </span>
  );
};
