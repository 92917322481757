import {
  getKeywordsByType,
  IProduct,
  keywordsToText,
} from "integrations/firebase/collections";
/**
 * KeywordService
 *
 * This service handles keyword related operations.
 * Currently a product behaves like a God-object, having lots of responsibilities.
 * The keyword service makes all keyword related use-cases available.
 */

export const DATABASE_KEYWORD = "database"; // keywords imported from an onix import (happens periodically)
/**
 * Attention: Custom keywords not always work as expected.
 * If we regenerate keywords, then previously generated keywords become custom keywords as we only calculate
 * custom keywords based on database keywords and currently generated keywords.
 */
export const CUSTOM_KEYWORD = "custom"; // keywords added by the user
export const GENERATED_KEYWORD = "generated"; // keywords gen by Reedy

// Define specific types for each keyword variant using TypeScript enums and discriminated unions
export enum KeywordType {
  Database = DATABASE_KEYWORD,
  Custom = CUSTOM_KEYWORD,
  Generated = GENERATED_KEYWORD,
}

export interface Keyword {
  id: string; // unique identifier for collections.
  value: string; // the keyword value.
  type: KeywordType; // the type of keyword.
}

/**
 * Union of two sets.
 * @param a set
 * @param b set
 * @returns union of a and b
 */
const union = <T>(a: Set<T>, b: Set<T>): Set<T> => new Set([...a, ...b]);

/**
 * Difference of two sets.
 * @param a set
 * @param b set
 * @returns difference of a and b
 */
const difference = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter((x) => !b.has(x)));

const intersection = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter((x) => b.has(x)));

/**
 * Create a keyword.
 * Using the value as ID for the SortableList component can work with stable unique identifiers
 * @param value The value of the keyword.
 * @param type The type of the keyword.
 * @returns The created keyword.
 * @example
 * createKeyword("test", DATABASE_KEYWORD);
 * // => { id: "test", value: "test", type: "database" }
 */
export const createKeyword = (value: string, type: KeywordType): Keyword => {
  return {
    id: value,
    value,
    type,
  };
};

/**
 * Implementation of the LoadKeywordsUseCase.
 * Loads keywords from a product in firebase with a subset of the IProduct interface.
 * @param product
 * @returns keywords with sources
 */
export const loadSelectedKeywords = (product?: IProduct) => {
  if (!product) {
    return [];
  }

  const { final, generated, database } = getKeywordsByType(product);

  const finalKeywords = new Set(keywordsToText(final));
  const databaseKeywords = new Set(keywordsToText(database));
  const generatedKeywords = new Set(keywordsToText(generated));

  const selectedDatabaseKeywords = new Set<string>(
    intersection(finalKeywords, databaseKeywords),
  );
  const selectedGeneratedKeywords = new Set<string>(
    intersection(finalKeywords, generatedKeywords),
  );
  const customKeywords = difference(
    finalKeywords,
    union(databaseKeywords, generatedKeywords),
  );

  // return db, gen and custom keywords
  return [
    ...[...customKeywords].map((keyword) =>
      createKeyword(keyword, KeywordType.Custom),
    ),
    ...[...selectedDatabaseKeywords].map((keyword) =>
      createKeyword(keyword, KeywordType.Database),
    ),
    ...[...selectedGeneratedKeywords].map((keyword) =>
      createKeyword(keyword, KeywordType.Generated),
    ),
  ];
};

type KeywordTypeReport = {
  type: KeywordType;
  average: number;
  total: number;
  keywords: Keyword["value"][];
};

type KeywordReport = {
  total: number;
  types: KeywordTypeReport[];
};

/**
 * ReportAverageNumberOfKeywordsInFinalSelectionUseCase
 * Reports the average number of keywords in the final selection.
 */
export interface ReportAverageNumberOfKeywordsInFinalSelectionUseCase {
  (keywords: Keyword[]): KeywordReport;
}

/**
 * Implementation of the ReportAverageNumberOfKeywordsInFinalSelectionUseCase.
 * Reports the average number of keywords in the final selection.
 * @param keywords
 * @returns
 */
export const reportAverageNumberOfKeywordsInFinalSelection: ReportAverageNumberOfKeywordsInFinalSelectionUseCase =
  (keywords) => {
    const total = keywords.length;
    const types = keywords.reduce((acc, keyword) => {
      const { type } = keyword;
      const typeReport = acc.find((report) => report.type === type);
      if (typeReport) {
        typeReport.total += 1;
        typeReport.average = +(typeReport.total / total).toFixed(4);
        typeReport.keywords.push(keyword.value);
      } else {
        acc.push({
          type,
          average: +(1 / total).toFixed(4),
          total: 1,
          keywords: [keyword.value],
        });
      }
      return acc;
    }, [] as KeywordTypeReport[]);

    return {
      total,
      types,
    };
  };

export type KeywordSelectionReport = {
  total_keywords: number;
  database_keywords: string[];
  custom_keywords: string[];
  generated_keywords: string[];
  average_database_keywords: number;
  average_custom_keywords: number;
  average_generated_keywords: number;
  [key: string]: any;
};

/**
 * GoogleAnalyticsKeywordSelectionReport
 * Flattens the report to be sent to Google Analytics.
 * @param product
 */
export const googleAnalyticsKeywordSelectionReport = (
  product: IProduct | undefined,
): KeywordSelectionReport => {
  const keywords = loadSelectedKeywords(product);
  const report = reportAverageNumberOfKeywordsInFinalSelection(keywords);
  const database = report.types.find((type) => type.type === "database");
  const custom = report.types.find((type) => type.type === "custom");
  const generated = report.types.find((type) => type.type === "generated");

  return {
    total_keywords: report.total ?? 0,
    custom_keywords: custom?.keywords ?? [],
    generated_keywords: generated?.keywords ?? [],
    database_keywords: database?.keywords ?? [],
    average_database_keywords: database?.average ?? 0,
    average_custom_keywords: custom?.average ?? 0,
    average_generated_keywords: generated?.average ?? 0,
  };
};
