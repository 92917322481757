import { Label } from "components/ui/label";
import { Checkbox } from "components/ui/checkbox";
import { useEffect } from "react";
import { DescriptionType } from "api/client/products";
import { supportedDescriptions } from "../../../../shared/constants";

interface DescriptionTypeMultiSelectProps {
  selectedDescriptionTypes: DescriptionType[];
  setSelectedDescriptionTypes: (types: DescriptionType[]) => void;
  existingDescriptionTypes: Array<DescriptionType>;
}

export function DescriptionTypeMultiSelect({
  selectedDescriptionTypes,
  setSelectedDescriptionTypes,
  existingDescriptionTypes,
}: DescriptionTypeMultiSelectProps) {
  const supportedDescriptionTypes = existingDescriptionTypes.filter(
    (d) =>
      supportedDescriptions.textTypes.includes(d.textType) &&
      supportedDescriptions.audiences.includes(d.audience.toLowerCase()),
  );

  useEffect(() => {
    if (
      supportedDescriptionTypes.length === 1 &&
      selectedDescriptionTypes.length === 0
    ) {
      setSelectedDescriptionTypes([supportedDescriptionTypes[0]]);
    }
  }, [
    supportedDescriptionTypes,
    selectedDescriptionTypes,
    setSelectedDescriptionTypes,
  ]);

  return (
    <div className="space-y-6 py-4">
      <div className="space-y-4">
        <div className="bg-secondary-50 rounded-lg p-4">
          <div className="grid gap-2">
            {supportedDescriptionTypes.map((type) => (
              <div
                key={`${type.textType}_${type.audience}`}
                className="flex items-center justify-between bg-white border border-secondary-200 rounded-md p-3"
              >
                <Label
                  htmlFor={`${type.textType}_${type.audience}`}
                  className="text-sm font-normal flex items-center gap-2 cursor-pointer"
                >
                  <span>{type.textType}</span>
                  <span className="text-secondary-400">{type.audience}</span>
                </Label>
                <Checkbox
                  id={`${type.textType}_${type.audience}`}
                  checked={selectedDescriptionTypes.some(
                    (t) =>
                      t.textType === type.textType &&
                      t.audience === type.audience,
                  )}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedDescriptionTypes([
                        ...selectedDescriptionTypes,
                        type,
                      ]);
                    } else {
                      setSelectedDescriptionTypes(
                        selectedDescriptionTypes.filter(
                          (t) =>
                            !(
                              t.textType === type.textType &&
                              t.audience === type.audience
                            ),
                        ),
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
