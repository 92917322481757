import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";

export function formatFirestoreTimestamp(timestamp?: Timestamp | null): string {
  if (!timestamp?.toDate?.()) {
    return " ";
  }

  const date = timestamp.toDate();
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatDate(date: Date): string {
  return format(date, "MMM d, yyyy");
}

export function formatTime(date: Date): string {
  return format(date, "HH:mm");
}

export function formatDateTime(date: Date): string {
  return format(date, "MMM d, yyyy, HH:mm");
}

export const areArraysEqualIgnoreOrder = (
  a: string[],
  b: string[],
): boolean => {
  return (
    new Set(a).size === new Set(b).size &&
    [...new Set(a)].every((value) => new Set(b).has(value))
  );
};

export const sleep = async (ms: number = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
