import { useCallback, useState } from "react";
import { Modal } from "components/modal/Modal";
import { useApi } from "hooks/useApi";
import { useDialog } from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useAuth } from "contexts/AuthContext";
import { UserRole } from "__generated__/models";
import { getEvaluations } from "integrations/firebase/collections/evaluations";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Button } from "components/button";
import { logError } from "shared/services/ErrorReporting";
import { COMPLETED_STATUSES } from "shared/constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { EvaluationCard } from "../../../components/EvaluationCard";

interface EvaluationModalProps {
  selectedProducts: string[] | "ALL";
}

export const EvaluationModal = ({ selectedProducts }: EvaluationModalProps) => {
  const api = useApi();
  const { userData } = useAuth();
  const { features } = useFeatureFlags();
  const { t } = useTranslation(["books"]);
  const warning = useDialog("evaluation-warning");
  const inProgress = useDialog("evaluation-in-progress");
  const [requestingEvaluation, setRequestingEvaluation] = useState(false);

  const [evaluations, loading] = useCollectionData(
    getEvaluations(userData?.organisation.id ?? ""),
  );

  const sortedEvaluations =
    evaluations?.sort(
      (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0),
    ) ?? [];

  const evaluationsInProgress = sortedEvaluations.filter(
    (evaluation) =>
      evaluation.status && !COMPLETED_STATUSES.includes(evaluation.status),
  );
  const lastEvaluationsNotInProgress = sortedEvaluations
    .filter(
      (evaluation) =>
        evaluation.status && COMPLETED_STATUSES.includes(evaluation.status),
    )
    .slice(0, 1);

  const evaluationInProgress = evaluationsInProgress.length > 0;

  const evaluationsToShowInWarning = evaluationInProgress
    ? evaluationsInProgress
    : lastEvaluationsNotInProgress;

  const handleOpenModal = useCallback(() => {
    if (evaluationInProgress) {
      inProgress.onOpen();
    } else {
      warning.onOpen();
    }
  }, [evaluationInProgress, inProgress, warning]);

  const onRunEvaluation = useCallback(async () => {
    try {
      if (requestingEvaluation) {
        return;
      }

      setRequestingEvaluation(true);

      await api.evaluation.evaluate(selectedProducts);

      warning.onClose();
      inProgress.onOpen();
    } catch (e) {
      logError(e);
      toast.error(t("books:evaluation.errorRunningEvaluation"));
    } finally {
      setRequestingEvaluation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, requestingEvaluation, warning.onClose, api]);

  if (
    !features.evaluation ||
    !userData?.roles?.includes(UserRole.SUPER_ADMIN)
  ) {
    return null;
  }

  const getTooltipText = () => {
    if (!selectedProducts.length) {
      return t("books:evaluation.triggerButton.tooltip.noBooksSelected");
    }
    if (evaluationInProgress) {
      return t("books:evaluation.triggerButton.tooltip.inProgress");
    }
    return null;
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>
            <Button
              variant="tertiary"
              disabled={
                loading ||
                requestingEvaluation ||
                evaluationInProgress ||
                !selectedProducts.length
              }
              icon="chart-pie"
              onClick={handleOpenModal}
            >
              {t("books:evaluation.triggerButton.caption")}
            </Button>
          </span>
        </TooltipTrigger>
        {getTooltipText() && (
          <TooltipContent>{getTooltipText()}</TooltipContent>
        )}
      </Tooltip>
      <Modal
        id={warning.id}
        open={warning.isOpen}
        onOpenChange={warning.onOpenChange}
        title={t("books:evaluation.modal.warning.title")}
        description={t("books:evaluation.modal.warning.description")}
        confirmLabel={t("books:evaluation.modal.warning.actions.confirm")}
        cancelLabel={t("books:evaluation.modal.warning.actions.cancel")}
        variant="warning"
        onConfirm={onRunEvaluation}
      />
      <Modal
        id={inProgress.id}
        open={inProgress.isOpen}
        onOpenChange={inProgress.onOpenChange}
        mainIconName="loader"
        title={t("books:evaluation.modal.progress.title")}
        description={t("books:evaluation.modal.progress.description")}
        variant="primary"
        cancelLabel={t("books:evaluation.modal.progress.actions.cancel")}
      >
        {evaluationsToShowInWarning.map((evaluation) => (
          <EvaluationCard
            key={evaluation.id}
            numberOfBooks={evaluation.data.requestedProducts.length}
            {...evaluation}
          />
        ))}
      </Modal>
    </>
  );
};
