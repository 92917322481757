import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "components/errors/ErrorPage";

export const NoProductFound: React.FC = () => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <ErrorPage
      title={t("productDetails:noProductFound.title")}
      message={t("productDetails:noProductFound.message")}
    />
  );
};
