import { SearchQueryRequest } from "__generated__/api";
import entries from "lodash/entries";

import { parseISO, format } from "date-fns";
import {
  DateFilterSchema,
  FilterTabs,
  FilterType,
  NumericFilterSchema,
  SearchFilterComparisonOperator,
  SearchQuery,
  SearchQueryFilters,
  SearchQuerySchema,
  SearchType,
} from "./types";

/*  --Functions to parse url params to form filters?--  */
const parseNumericFilter = (filter: string): SearchQueryFilters | undefined => {
  const [query, value] = filter.split(":");
  const [variable, operator] = query.split(".");

  if (!variable || !operator || Number.isNaN(Number(value))) {
    return;
  }

  return {
    [variable as keyof SearchQueryFilters]: NumericFilterSchema.parse({
      value: Number(value),
      comparisonOperator: operator as SearchFilterComparisonOperator,
      type: FilterType.NUMERIC,
    }),
  };
};

const parseDateRangeFilter = (
  filter: string,
): SearchQueryFilters | undefined => {
  const [variable, range] = filter.split(":");
  const [start, end] = range.slice(1, -1).split("..");

  if (!variable || !start || !end) {
    return;
  }

  return {
    [variable as keyof SearchQueryFilters]: DateFilterSchema.parse({
      tab: FilterTabs.RANGE,
      range: {
        from: start,
        to: end,
      },
      type: FilterType.DATE_RANGE,
    }),
  };
};

export function parseParamFilters(filters: string[]): SearchQuery["filters"] {
  if (!filters.length) {
    return {};
  }

  const parsedFilters: SearchQuery["filters"] = {};
  filters.forEach((filter) => {
    const numericRegex = /^[a-zA-Z]+\.[a-zA-Z]+:\d+$/;
    const dateRangeRegex =
      /^[a-zA-Z]+:\[\d{4}-\d{2}-\d{2}\.\.\d{4}-\d{2}-\d{2}\]$/;

    if (numericRegex.test(filter)) {
      const parsed = parseNumericFilter(filter);
      if (parsed) {
        Object.assign(parsedFilters, parsed);
      }
    }

    if (dateRangeRegex.test(filter)) {
      const parsed = parseDateRangeFilter(filter);

      if (parsed) {
        Object.assign(parsedFilters, parsed);
      }
    }
  });
  return parsedFilters;
}

export function paramsToSearchQuery(params: URLSearchParams): SearchQuery {
  const filters = parseParamFilters(params.getAll("filter"));

  const query = params.get("q");
  const type = params.get("type");
  const page = params.get("page");
  const pageSize = params.get("pageSize");

  return SearchQuerySchema.parse({
    type: type ?? SearchType.QUICK,
    query: query ?? "",
    filters,
    page,
    pageSize,
  });
}

export const DEFAULT_PARAMS: SearchQueryRequest = {
  model: "products",
  q: "",
  query_by: "title,author,identifier",
  sort_by: "publishedAt:desc",
  filter_by: "",
  page: 1,
  per_page: 10,
  prefix: "false",
} as const;

export function parseComparisonOperator(
  comparisonOperator: SearchFilterComparisonOperator,
) {
  switch (comparisonOperator) {
    case SearchFilterComparisonOperator.EQUALS:
      return "=";
    case SearchFilterComparisonOperator.LESS:
      return "<";
    case SearchFilterComparisonOperator.GREATER:
      return ">";
    default:
      throw new Error("Invalid comparison operator");
  }
}

/*  --Function to parse url filters to typesense filter--  */

const SECONDS_IN_MS = 1000;

export function datesToISOString(start: string, end: string) {
  const startDate = new Date(start);
  startDate.setUTCHours(0, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setUTCHours(23, 59, 59, 999);

  return [
    Math.floor(startDate.getTime() / SECONDS_IN_MS),
    Math.floor(endDate.getTime() / SECONDS_IN_MS),
  ];
}

export function searchQueryFiltersToFilter(filters?: SearchQueryFilters) {
  const conditions: string[] = [];

  if (!filters) {
    return "";
  }

  entries(filters).forEach(([variable, filter]) => {
    if (!filter) {
      return;
    }

    if (filter.type === FilterType.NUMERIC) {
      const operator = filter.comparisonOperator
        ? parseComparisonOperator(filter.comparisonOperator)
        : undefined;
      conditions.push(`${variable}:${operator}${filter.value}`);
    } else if (filter.type === FilterType.DATE_RANGE && filter.range) {
      const { from, to } = filter.range;
      const [start, end] = datesToISOString(from, to);

      conditions.push(`${variable}:[${start}..${end}]`);
    }
  });

  return conditions.join(" && ");
}

export function searchQueryToSearchRequest(
  query: Partial<SearchQuery>,
): SearchQueryRequest {
  const baseRequest: SearchQueryRequest = {
    ...DEFAULT_PARAMS,
    q: query.query || "",
    page: query.page || 1,
    per_page: query.pageSize || 10,
    filter_by: searchQueryFiltersToFilter(query.filters),
  };

  switch (query.type) {
    case SearchType.SEMANTIC: {
      return {
        ...baseRequest,
        q: query.query || "*",
        page: 0,
        query_by: "summaryEmbedding",
        sort_by: "_vector_distance:asc",
      };
    }
    case SearchType.ISBN: {
      return {
        ...baseRequest,
        prefix: undefined,
        q: "",
        filter_by: query.query ? `identifier: [${query.query}]` : "",
      };
    }
    default: {
      return baseRequest;
    }
  }
}

export const vectorDistanceToPercentage = (distance: number) => {
  // typesense returns a number between 0 and 2, we convert it to a percentage
  const percentage = 100 - distance * 50;

  // First clamp the input to 0-100
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  // Scale the percentage so that 60% = 0% and 80% = 100%
  const scaledPercentage = Math.round(((clampedPercentage - 60) / 20) * 100);

  // Clamp the final result to 0-100 range
  return Math.min(100, Math.max(0, scaledPercentage));
};

export const getFormattedDate = (date: string) =>
  date ? format(parseISO(date), "yyyy-MM-dd") : "";
