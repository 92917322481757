import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useProductActions } from "hooks/useProductActions";
import { logError } from "shared/services/ErrorReporting";
import { getDescription } from "reedy-data";
import { toast } from "../utils/toast";
import { useProduct } from "./ProductContext";
import { getDefaultDescription } from "../integrations/firebase/collections";
import { useProductDescriptionGenerations } from "../hooks/useProductDescriptionGenerations";

interface BookDescriptionContextType {
  infoToast: string | undefined;
  showDetailsGenerateBookDescription: boolean;
  selectedTarget: string | null;
  specialDetails: string | null;
  generateBookDescription: (
    regenerate?: boolean,
    hash?: string,
  ) => Promise<void>;
  setSelectedTarget: (selectedTarget: string | null) => void;
  setSpecialDetails: (specialDetails: string | null) => void;
  handleCopyDoneDatabaseBookDescription: () => void;
  handleCopyDoneGeneratedBookDescription: () => void;
  onToastClose: () => void;
  onUpdateGeneratedDescription: (
    hash: string,
    updatedText: string,
  ) => Promise<void>;
  setShowDetailsGenerateBookDescription: (
    showDetailsGenerateBookDescription: boolean,
  ) => void;
  setSelectedDescriptionHash: (hash?: string) => void;
  selectedDescriptionHash?: string;
}

export const BookDescriptionContext = createContext<BookDescriptionContextType>(
  undefined as never,
);

export const useBookDescription = () => useContext(BookDescriptionContext);

export const BookDescriptionProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(["general", "products"]);
  const { product } = useProduct();
  const { gaEvent } = useAnalytics();
  const [infoToast, setInfoToast] = useState<string>();
  const [
    showDetailsGenerateBookDescription,
    setShowDetailsGenerateBookDescription,
  ] = useState(false);
  const { generateProductDescription, setProductDescription } =
    useProductActions();

  const [selectedDescriptionHash, setSelectedDescriptionHash] =
    useState<string>();

  const [generations = []] = useProductDescriptionGenerations(
    product?.id,
    product?.organisation?.id,
  );

  const [latestGenerationRequest] = generations;

  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const [specialDetails, setSpecialDetails] = useState<string | null>(null);

  useEffect(() => {
    setSelectedTarget(latestGenerationRequest?.data?.targetGroup || null);
    setSpecialDetails(latestGenerationRequest?.data?.specialRequest || null);
  }, [latestGenerationRequest]);

  const generateBookDescription = useCallback(
    async (regenerate?: boolean, passedHash?: string) => {
      setShowDetailsGenerateBookDescription(false);
      if (!product) {
        logError("Product not found while generating description");
        return;
      }
      const hash = passedHash ?? getDefaultDescription(product)?.hash;
      if (!hash) {
        logError(`Unable to determine description to update. Hash is ${hash}`);
        return;
      }

      await generateProductDescription(
        product,
        hash,
        selectedTarget || "",
        specialDetails || "",
        { regenerate },
      );
    },
    [product, selectedTarget, specialDetails, generateProductDescription],
  );

  const handleCopyDoneDatabaseBookDescription = useCallback(() => {
    toast.success(
      t(
        "products:bookDescription.tabDatabase.toasts.info.copyDoneDatabaseBookDescription",
      ),
    );
  }, [t]);

  const handleCopyDoneGeneratedBookDescription = useCallback(() => {
    toast.success(
      t(
        "products:bookDescription.tabGenerate.toasts.info.copyDoneGeneratedBookDescription",
      ),
    );

    gaEvent({
      type: "generated_description_copied_to_clipboard",
      payload: {
        description:
          getDescription(product, selectedDescriptionHash)?.textGenerated ?? "",
        item_id: product?.isbn || "",
        item_name: product?.title || "",
        book: product?.isbn || "",
      },
    });
  }, [t, gaEvent, product, selectedDescriptionHash]);

  const onToastClose = useCallback(() => {
    setInfoToast(undefined);
  }, []);

  const onUpdateGeneratedDescription = useCallback(
    async (hash: string, updatedText: string) => {
      if (!product?.id) {
        logError("Product not found while updating description");
        return;
      }

      await setProductDescription(product.id, hash, updatedText);

      gaEvent({
        type: "book_description_manually_edited",
        payload: {
          item_id: product.isbn,
          item_name: product.title,
          book: product.isbn,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product?.id, setProductDescription, gaEvent],
  );

  const authProviderValue = useMemo(
    () => ({
      infoToast,
      showDetailsGenerateBookDescription,
      selectedTarget,
      specialDetails,
      generateBookDescription,
      setSelectedTarget,
      setSpecialDetails,
      handleCopyDoneDatabaseBookDescription,
      handleCopyDoneGeneratedBookDescription,
      onToastClose,
      onUpdateGeneratedDescription,
      setShowDetailsGenerateBookDescription,
      setSelectedDescriptionHash,
      selectedDescriptionHash,
    }),
    [
      infoToast,
      showDetailsGenerateBookDescription,
      selectedTarget,
      specialDetails,
      generateBookDescription,
      setSelectedTarget,
      setSpecialDetails,
      handleCopyDoneDatabaseBookDescription,
      handleCopyDoneGeneratedBookDescription,
      onToastClose,
      onUpdateGeneratedDescription,
      setShowDetailsGenerateBookDescription,
      selectedDescriptionHash,
      setSelectedDescriptionHash,
    ],
  );
  return (
    <BookDescriptionContext.Provider value={authProviderValue}>
      {children}
    </BookDescriptionContext.Provider>
  );
};
