import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

const useTruncated = () => {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = React.useState(false);

  React.useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    let timeoutId: NodeJS.Timeout;
    const debouncedCheck = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(checkTruncation, 100);
    };

    checkTruncation();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
      clearTimeout(timeoutId);
    };
  }, []);

  return { ref, isTruncated };
};

export const TruncatedText = ({ text }: { text: string | undefined }) => {
  const { ref, isTruncated } = useTruncated();

  if (!text) return null;

  if (isTruncated) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <span ref={ref} className="truncate block w-full" aria-label={text}>
            {text}
          </span>
        </TooltipTrigger>
        <TooltipContent>{text}</TooltipContent>
      </Tooltip>
    );
  }

  return (
    <span ref={ref} className="truncate block w-full" aria-label={text}>
      {text}
    </span>
  );
};
