import { SkeletonFinalSubject } from "components/skeletons/SkeletonFinalSubject";

interface FinalSectionEmptyProps {
  header: string;
  description: string;
}

export const FinalSectionEmpty = ({
  header,
  description,
}: FinalSectionEmptyProps) => (
  <div
    className="flex flex-col items-center m-4 text-center"
    data-testid="final-section-empty"
  >
    <div className="hide-on-short-screen">
      <SkeletonFinalSubject className="scale-75 opacity-80" />
      <SkeletonFinalSubject className="scale-75" primary />
      <SkeletonFinalSubject className="scale-75 opacity-80" />
    </div>
    <div className="flex flex-col my-8 gap-2">
      <p className="font-semibold text-secondary-900">{header}</p>

      <p className="text-xs text-secondary-500">{description}</p>
    </div>
  </div>
);
