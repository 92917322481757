import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { useFormContext } from "react-hook-form";
import { FilterTabs } from "hooks/search/types";
import { useTranslation } from "react-i18next";
import { SpecificDateFilter } from "./SpecificDateFilter";
import { DateRangeFilter } from "./DateRangeFilter";

export const DateFilter = ({ name }: { name: string }) => {
  const { t } = useTranslation(["books"]);
  const { watch, setValue } = useFormContext();

  const currentTab = watch(`filters.${name}.tab`);
  const currentRange = watch(`filters.${name}.range`);

  const handleTabChange = (value: string) => {
    setValue(`filters.${name}.tab`, value as FilterTabs);
    if (value === FilterTabs.SPECIFIC && currentRange.from) {
      setValue(`filters.${name}.range`, {
        from: currentRange.from,
        to: currentRange.from,
      });
    }
  };

  return (
    <Tabs value={currentTab} onValueChange={handleTabChange}>
      <TabsList className="rounded-none w-[calc(100%+1rem)] bg-secondary-25 -translate-x-1 -translate-y-1 h-12 flex justify-start items-end p-0 border-b">
        <TabsTrigger value={FilterTabs.SPECIFIC}>
          {t("books:filters.dateFilter.specific")}
        </TabsTrigger>
        <TabsTrigger value={FilterTabs.RANGE}>
          {t("books:filters.dateFilter.range")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value={FilterTabs.SPECIFIC}>
        <SpecificDateFilter name={name} />
      </TabsContent>
      <TabsContent value={FilterTabs.RANGE}>
        <DateRangeFilter name={name} />
      </TabsContent>
    </Tabs>
  );
};
