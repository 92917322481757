import { useTranslation } from "react-i18next";
import { ErrorPage } from "components/errors/ErrorPage";

interface ErrorMessageProps {
  backLink: string;
}

export const ErrorMessage = ({ backLink }: ErrorMessageProps) => {
  const { t } = useTranslation(["projectDetails"]);

  return (
    <ErrorPage
      title={t("projectDetails:notFound.title")}
      message={t("projectDetails:notFound.message")}
      backLink={backLink}
    />
  );
};
