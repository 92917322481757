export interface LaunchDarklyFeatureFlags {
  themaCodes: boolean;
  operationalThemaVersion: string;
  flagsAuthLoaded: boolean;
  rankKeywords: boolean;
  onixExport: boolean;
  evaluation: boolean;
  operationalMaintenanceMode: boolean;
  publishedDateFilter: boolean;
}

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    themaCodes: flags.themaCodes ?? false,
    operationalThemaVersion: flags.operationalThemaVersion ?? "-",
    flagsAuthLoaded: flags.flagsAuthLoaded ?? false,
    rankKeywords: flags.rankKeywords ?? false,
    onixExport: flags.onixExport ?? false,
    evaluation: flags.evaluation ?? false,
    maintenanceMode: flags.operationalMaintenanceMode ?? false,
    publishedDateFilter: flags.publishedDateFilter ?? false,
  };
}

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
