import React from "react";

import { DetailsInfo } from "./details/DetailsInfo";

type ProductSummaryProps = {
  title: string;
  authors: string[];
  isbn: string;
  productGroupDescription: string;
};

export const ProductSummary: React.FC<ProductSummaryProps> = ({
  title,
  authors,
  isbn,
  productGroupDescription,
}) => (
  <div className="space-y-3">
    <div className="text-4xl font-medium text-neutral-800">{title}</div>
    <DetailsInfo
      authors={authors}
      productGroupDescription={productGroupDescription}
      isbn={isbn}
    />
  </div>
);
