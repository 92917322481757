import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { SearchFilterComparisonOperator } from "hooks/search/types";
import { Controller } from "react-hook-form";

interface ComparisonOperatorDropdownProps {
  name: string;
}

export const ComparisonOperatorDropdown = ({
  name,
}: ComparisonOperatorDropdownProps) => {
  const { t } = useTranslation(["books"]);

  const comparisonTypeTranslations = useMemo(
    () => ({
      [SearchFilterComparisonOperator.GREATER]: t(
        "books:filter.keywordTypes.greater",
      ),
      [SearchFilterComparisonOperator.LESS]: t(
        "books:filter.keywordTypes.less",
      ),
      [SearchFilterComparisonOperator.EQUALS]: t(
        "books:filter.keywordTypes.equals",
      ),
    }),
    [t],
  );

  return (
    <span className="whitespace-nowrap">
      <Controller
        name={name}
        defaultValue={SearchFilterComparisonOperator.GREATER}
        render={({ field }) => (
          <Select
            value={field.value}
            onValueChange={(v) =>
              field.onChange(v as SearchFilterComparisonOperator)
            }
          >
            <SelectTrigger className="h-full border-secondary-300 rounded-e-none bg-secondary-50 min-w-28">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {Object.entries(SearchFilterComparisonOperator).map(
                  ([key, operator]) => (
                    <SelectItem
                      key={key}
                      value={operator}
                      analytics-id="button_keyword_filter_type"
                      className="text-s"
                    >
                      {comparisonTypeTranslations[operator]}
                    </SelectItem>
                  ),
                )}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
      />
    </span>
  );
};
