import { ButtonCopyTextToClipboard } from "components/button/ButtonCopyTextToClipboard";
import { useProduct } from "contexts/ProductContext";
import { useTranslation } from "react-i18next";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { BasePanel } from "components/BasePanel";
import { getDescription } from "reedy-data";
import { DatabaseBookDescriptionEmpty } from "./DatabaseBookDescriptionEmpty";
import { Editor } from "../Editor";
import {
  getKeywordsByType,
  keywordsToText,
} from "../../../../../../integrations/firebase/collections";

export const DatabaseDescription = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { selectedDescriptionHash, handleCopyDoneDatabaseBookDescription } =
    useBookDescription();
  const { generated, database } = getKeywordsByType(product);

  const selectedDescription = getDescription(product, selectedDescriptionHash);
  const bookDescription = selectedDescription?.text;

  const keywords = {
    generated: keywordsToText(generated),
    database: keywordsToText(database),
  };

  return (
    <BasePanel
      title={t(
        "productDetails:bookDescription.tabDatabase.databaseBookDescription",
      )}
      isEmpty={!bookDescription}
      emptyProps={{
        emptyComponent: <DatabaseBookDescriptionEmpty />,
      }}
      className="h-[var(--subject-list-container-height)]"
      wrapper={false}
      headerAction={
        bookDescription && (
          <ButtonCopyTextToClipboard
            textToCopy={bookDescription}
            tooltipText={t("general:tooltips.copyToClipboard")}
            onDone={handleCopyDoneDatabaseBookDescription}
            buttonText={`${t("general:buttons.copy")}`}
          />
        )
      }
      border={false}
    >
      {bookDescription && (
        <Editor
          key={`${product?.id}-${selectedDescriptionHash}`}
          className="h-[calc(100%-48px)]"
          initialValue={bookDescription}
          keywords={keywords}
          disabled
        />
      )}
    </BasePanel>
  );
};
