import { useMemo } from "react";
import { useProduct } from "contexts/ProductContext";
import { filterQualifiers } from "../../utils";
import { QualifiersListVariant } from "./types";

export const useQualifiersData = (variant: QualifiersListVariant) => {
  const { product } = useProduct();

  const databaseQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.database || []),
    [product?.subjects?.database],
  );

  const generatedQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.generated || []),
    [product?.subjects?.generated],
  );

  const finalQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.final || []),
    [product?.subjects?.final],
  );

  const qualifiers =
    variant === "database" ? databaseQualifiers : generatedQualifiers;
  const isEmptyList = qualifiers.length === 0;

  return { qualifiers, finalQualifiers, isEmptyList };
};
