import {
  collection,
  CollectionReference,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import { FirestoreGenerationRequestEvaluation } from "__generated__/models";

interface FirestoreEvaluationFromFirebase
  extends Omit<
    FirestoreGenerationRequestEvaluation,
    "createdAt" | "updatedAt"
  > {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  data: FirestoreGenerationRequestEvaluation["data"];
}

export type { FirestoreGenerationRequestEvaluation };

const evaluationConverter = {
  toFirestore(evaluation: FirestoreGenerationRequestEvaluation) {
    return evaluation;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirestoreEvaluationFromFirebase>,
  ): FirestoreGenerationRequestEvaluation {
    const evaluation = snapshot.data();
    return {
      ...evaluation,
      createdAt: evaluation.createdAt.toDate(),
      updatedAt: evaluation.updatedAt?.toDate(),
    };
  },
};

export const getEvaluationsCollection = () =>
  collection(db, "generationRequestsEvaluations").withConverter(
    evaluationConverter,
  ) as CollectionReference<FirestoreGenerationRequestEvaluation>;

export const getEvaluations = (organisationId: string) =>
  query(
    getEvaluationsCollection(),
    where("organisation.id", "==", organisationId),
  );
