import { api } from "api/client";
import { useAuth } from "contexts/AuthContext";
import { useMemo } from "react";

export function useApi() {
  const { authToken } = useAuth();

  return useMemo(() => {
    api.setAuthToken(authToken);

    return api;
  }, [authToken]);
}
