import { useFormContext } from "react-hook-form";
import { parseISO } from "date-fns";

import { toast } from "sonner";
import { IconInput } from "components/input/IconInput";
import { Calendar } from "components/ui/calendar";
import { ChangeEvent } from "react";
import { getFormattedDate } from "hooks/search/utils";
import { transformToInputDate } from "../utils";

export const SpecificDateFilter = ({ name }: { name: string }) => {
  const { setValue, watch } = useFormContext();

  const formDate = watch(`filters.${name}.range`);

  const handleDateChange = (value: Date | undefined) => {
    if (!value) return;
    const updatedRange = {
      from: transformToInputDate(value),
      to: transformToInputDate(value),
    };
    setValue(`filters.${name}.range`, updatedRange);
  };

  const getValue = (
    date: { from?: string; to?: string } | undefined,
  ): string => {
    return getFormattedDate(date?.from ?? "");
  };

  const getParsedDate = (
    date: { from?: string; to?: string } | undefined,
  ): Date | undefined => {
    try {
      return date?.from ? parseISO(date.from) : undefined;
    } catch (error) {
      toast.error(`Error parsing date: ${error}`);
      return undefined;
    }
  };

  const handleCalendarChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const date = new Date(e.target.value);
      if (Number.isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }
      handleDateChange(date);
    } catch (error) {
      toast.error("Please enter a valid date in YYYY-MM-DD format");
    }
  };

  return (
    <div className="p-2">
      <IconInput
        type="date"
        iconName="calendar"
        aria-label="specific date input"
        value={getValue(formDate)}
        onChange={handleCalendarChange}
      />
      <Calendar
        onSelect={handleDateChange}
        selected={getParsedDate(formDate)}
        mode="single"
      />
    </div>
  );
};
