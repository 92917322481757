import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { OrganisationBanner } from "components/banner/OrganisationBanner";
import { AuthHeader } from "./AuthHeader";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="max-h-full flex flex-col">
      <AuthHeader />
      <OrganisationBanner />
      <main className="px-16 py-8 overflow-auto" ref={scrollRef}>
        {children}
      </main>
    </div>
  );
};
