import { FallbackRender } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "components/errors/ErrorPage";

export const ErrorFallback: FallbackRender = ({ error }) => {
  const { t } = useTranslation(["error"]);

  return (
    <ErrorPage
      title={t("error:fallback.title")}
      message={t("error:fallback.message")}
      error={error as Error}
      onRetry={() => window.location.reload()}
    />
  );
};
