import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useBacklist } from "contexts/BacklistContext";
import { TabHeader } from "components/TabHeader";
import { Table } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { EvaluationModal } from "../evaluation/EvaluationModal";

interface BooksPageHeaderProps {
  table: Table<IProduct>;
}

export const BooksPageHeader = ({ table }: BooksPageHeaderProps) => {
  const {
    data: { count, total },
    isLoading,
  } = useBacklist();

  const { t } = useTranslation(["books"]);

  const subtitle = useMemo(() => {
    if (count !== null && total !== null) {
      return t("books:header.subtitle.booksOfTotal", {
        count,
        total,
      });
    }

    if (isLoading) {
      return t("books:header.subtitle.loading");
    }

    if (count === null && total === null) {
      return "";
    }

    if (total !== null) {
      return t("books:header.subtitle.booksInDatabase", {
        count: total,
      });
    }

    return t("books:header.subtitle.booksFound", { count });
  }, [count, total, isLoading, t]);

  const isSelectedAll = table.getSelectedAll();
  const selectedProducts = isSelectedAll ? "ALL" : table.getSelectedIds();

  return (
    <div className="flex justify-between items-center">
      <TabHeader title={t("books:header.title")} subtitle={subtitle} />
      <EvaluationModal selectedProducts={selectedProducts} />
    </div>
  );
};
