import { format } from "date-fns";
import {
  DateFilterSchema,
  Filter,
  ValidNumericFilterSchema,
} from "hooks/search/types";

export const isValidFilter = (filter: Filter): boolean =>
  [ValidNumericFilterSchema, DateFilterSchema].some(
    (schema) => schema.safeParse(filter).success,
  );

export const getNoOfValidFilters = (filters: Record<string, Filter>): number =>
  Object.entries(filters).filter(([, filter]) => isValidFilter(filter)).length;

export const transformToInputDate = (date: Date | string) => {
  if (!date || typeof date === "string") return date;
  return format(date, "yyyy-MM-dd");
};
