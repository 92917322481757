import { ReactNode } from "react";
import { cn } from "utils";

export interface ListItemProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  testId?: string;
}

export const ListItem = ({
  children,
  className,
  onClick,
  testId,
}: ListItemProps) => {
  const sharedProps = {
    className: cn(
      "flex w-full items-center justify-between bg-transparent group flex-row px-4 py-4 border-b",
      className,
    ),
    "data-testid": testId,
  };

  if (onClick) {
    return (
      <button type="button" onClick={onClick} {...sharedProps}>
        {children}
      </button>
    );
  }

  return <div {...sharedProps}>{children}</div>;
};
