/* eslint-disable @typescript-eslint/no-shadow */
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { IProduct } from "integrations/firebase/collections";
import { PaginationState } from "@tanstack/react-table";
import { useSearchQuery } from "hooks/search/useSearchQuery";
import { useSearch } from "hooks/search/useSearch";
import { mapTypesenseToProduct } from "hooks/search/queries";
import { SearchQuery, SearchType } from "hooks/search/types";
import { SearchQueryRequest } from "__generated__/api";
import { useAnalytics } from "./AnalyticsContext";

interface IProductWithDistance extends IProduct {
  vectorDistance?: number;
}

interface Data {
  total: number | null;
  count: number;
  products: IProductWithDistance[];
}

interface IBacklistContext {
  data: Data;
  isLoading: boolean;
  error?: Error | null;
  searchQuery: SearchQuery;
  pagination: PaginationState;
  searchRequestParams: SearchQueryRequest;

  setSearchQuery(query: Partial<SearchQuery>): void;

  setPagination(
    state: PaginationState | ((state: PaginationState) => PaginationState),
  ): void;
}

export const BacklistContext = createContext<IBacklistContext>(
  undefined as never,
);

export const BacklistContextProvider = ({ children }: PropsWithChildren) => {
  const { value: searchQuery, setQuery: setSearchQuery } = useSearchQuery();
  const { pagination } = searchQuery;
  const { gaEvent } = useAnalytics();

  // Get the total number of books in the organization
  const { data: total } = useSearch({ query: "*", type: SearchType.QUICK });

  const setPagination = useCallback(
    (
      state: PaginationState | ((state: PaginationState) => PaginationState),
    ) => {
      const value = typeof state === "function" ? state(pagination) : state;

      setSearchQuery({
        page: value.pageIndex,
        pageSize: value.pageSize,
      });
    },
    [setSearchQuery, pagination],
  );

  const onSearch = useCallback(() => {
    gaEvent({
      type: "search_books",
      payload: {
        type: searchQuery.type,
        query: searchQuery.query,
        keywords_filter_type:
          searchQuery.filters?.keywordCount?.comparisonOperator,
        keywords_filter_count:
          searchQuery.filters?.keywordCount?.value !== undefined
            ? Number(searchQuery.filters?.keywordCount?.value)
            : undefined,
      },
    });
  }, [searchQuery, gaEvent]);

  const {
    error,
    data: searchResponse,
    isLoading,
    requestParams: searchRequestParams,
    isPlaceholderData,
  } = useSearch(searchQuery, { onSearch });

  const data = useMemo(() => {
    const documents =
      searchResponse?.hits?.map((hit) => ({
        ...hit.document,
        vector_distance: hit.vector_distance,
      })) || [];
    const products: IProductWithDistance[] = documents.map((doc) => ({
      ...mapTypesenseToProduct(doc),
      vector_distance: doc.vector_distance ?? undefined,
    }));

    return {
      total: total?.found ?? null,
      count: searchResponse?.found ?? 0,
      products,
    };
  }, [searchResponse, total?.found]);

  const backlistContextProviderValue = useMemo(() => {
    return {
      data,
      isLoading: isLoading || isPlaceholderData,
      setPagination,
      error,
      searchQuery,
      searchRequestParams,
      setSearchQuery,
      pagination,
    };
  }, [
    data,
    isLoading,
    isPlaceholderData,
    setPagination,
    error,
    searchQuery,
    searchRequestParams,
    setSearchQuery,
    pagination,
  ]);

  return (
    <BacklistContext.Provider value={backlistContextProviderValue}>
      {children}
    </BacklistContext.Provider>
  );
};

export const useBacklist = () => {
  const ctxt = useContext(BacklistContext);
  if (!ctxt) {
    throw new Error(
      "useBacklist must be used within a BacklistContextProvider",
    );
  }
  return ctxt;
};
