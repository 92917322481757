import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/button";
import { Badge } from "components/ui/badge";
import { Loader } from "../../../../../../components/loading/Loader";
import {
  OrganisationOnixUploadFileReportDialogTable,
  UploadReportProduct,
} from "./OrganisationOnixUploadFileReportDialogTable";

export const OrganisationOnixUploadFileReportDialog = ({
  open,
  loading,
  products,
  onClose,
  onExportClick,
  total,
  succeeded,
  failed,
}: {
  open: boolean;
  loading?: boolean;
  products: UploadReportProduct[];
  onClose: () => void;
  onExportClick: () => void;
  total?: number;
  succeeded?: number;
  failed?: number;
}) => {
  const { t } = useTranslation(["settings"]);

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="overflow-visible min-w-[90%] h-[70vh] flex flex-col">
        <DialogHeader className="items-start space-y-0 my-0 mb-8">
          <DialogTitle className="mb-3 flex items-center">
            {loading ? <Loader className="size-6 mr-3 border-2" /> : null}
            {t("settings:organisation.onix.report.dialog.title")}
          </DialogTitle>
          <DialogDescription className="text-left" asChild>
            <div>
              <span className="text-secondary-400 uppercase mr-2">
                {t("settings:organisation.onix.report.dialog.subtitle")}
              </span>
              {total ? (
                <span className="text-secondary-500">
                  {t("settings:organisation.onix.misc.books", { count: total })}
                </span>
              ) : null}
              {failed ? (
                <>
                  <span className="text-secondary-300 mx-2">|</span>
                  <span className="text-secondary-500">
                    {t("settings:organisation.onix.misc.books", {
                      count: failed,
                    })}
                  </span>
                  <Badge variant="error" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.failed")}
                  </Badge>
                </>
              ) : null}
              {succeeded ? (
                <>
                  <span className="text-secondary-300 mx-2">|</span>
                  <span className="text-secondary-500">
                    {t("settings:organisation.onix.misc.books", {
                      count: succeeded,
                    })}
                  </span>
                  <Badge variant="success" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.success")}
                  </Badge>
                </>
              ) : null}
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 overflow-y-auto">
          <OrganisationOnixUploadFileReportDialogTable
            products={products}
            loading={loading}
          />
        </div>

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <Button
              label={t("settings:organisation.onix.report.dialog.cancel")}
              onClick={onClose}
              variant="tertiary"
              size="small"
            />
            <Button
              label={t("settings:organisation.onix.report.dialog.export")}
              size="small"
              onClick={onExportClick}
              disabled={loading || products.length === 0}
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
