import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuth } from "contexts/AuthContext";
import { getEvaluations } from "integrations/firebase/collections/evaluations";
import { FirestoreGenerationStatus } from "__generated__/api";
import { UserRole } from "__generated__/models";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/button";
import { logError } from "shared/services/ErrorReporting";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { COMPLETED_STATUSES } from "shared/constants";
import { EvaluationCard } from "../../../components/EvaluationCard";

export const EvaluationResults = () => {
  const { features } = useFeatureFlags();
  const { t } = useTranslation(["books"]);
  const { userData, organisation } = useAuth();
  const [showAllResults, setShowAllResults] = useState(false);

  const [evaluations, loading, errorLoadingEvaluations] = useCollectionData(
    organisation ? getEvaluations(organisation.id) : null,
  );

  if (errorLoadingEvaluations) {
    logError(errorLoadingEvaluations);
  }

  if (!features.evaluation) {
    return null;
  }
  if (loading) {
    return <div className="animate-pulse h-32 bg-gray-100 rounded-md" />;
  }

  const sortedEvaluations =
    evaluations?.sort(
      (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0),
    ) ?? [];

  const completedEvaluations = sortedEvaluations.filter(
    (evaluation) => evaluation.status === FirestoreGenerationStatus.COMPLETED,
  );
  const inProgressEvaluations = sortedEvaluations.filter(
    (evaluation) =>
      evaluation.status && !COMPLETED_STATUSES.includes(evaluation.status),
  );

  const isSuperAdmin = userData?.roles?.includes(UserRole.SUPER_ADMIN);
  const highlightText = isSuperAdmin
    ? t("books:evaluation.noResults.admin.highlight")
    : t("books:evaluation.noResults.user.highlight");
  const detailsText = isSuperAdmin
    ? t("books:evaluation.noResults.admin.details")
    : t("books:evaluation.noResults.user.details");

  const newestEvaluation = completedEvaluations[0] ?? inProgressEvaluations[0];
  const hasMultipleResults = evaluations && evaluations.length > 1;

  return (
    <>
      <div className="space-y-4">
        <h2 className="text-neutral-700 text-xl font-semibold">
          {t("books:evaluation.results.title")}
        </h2>
        {!newestEvaluation && (
          <div className="flex-1 bg-secondary-25 rounded-lg flex items-center px-8 py-10 space-x-3">
            <div className="text-secondary-400 text-sm">
              <span className="font-bold text-secondary-500">
                {highlightText}
              </span>
              {" - "}
              <span>{detailsText}</span>
            </div>
          </div>
        )}
        <div>
          {newestEvaluation && (
            <EvaluationCard
              numberOfBooks={newestEvaluation.data.requestedProducts.length}
              {...newestEvaluation}
            />
          )}
          {hasMultipleResults && (
            <Button
              variant="ghost"
              size="small"
              label={t("books:evaluation.showMore")}
              onClick={() => setShowAllResults(true)}
              icon="arrow-right"
            />
          )}
        </div>
      </div>

      <Dialog open={showAllResults} onOpenChange={setShowAllResults}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("books:evaluation.allResults")}</DialogTitle>
            <DialogDescription>
              {t("books:evaluation.allResultsDescription")}
            </DialogDescription>
          </DialogHeader>
          <div className="max-h-[400px] overflow-y-auto space-y-3">
            {sortedEvaluations.map((evaluation) => (
              <EvaluationCard
                key={evaluation.id}
                numberOfBooks={evaluation.data.requestedProducts.length}
                {...evaluation}
              />
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
