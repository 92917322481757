import { useTranslation } from "react-i18next";
import { BaseListContent } from "components/BaseListContent";
import { FirestoreGenerationStatusCode } from "../../../../__generated__/models";
import { generationErrorMessage } from "../../utils";

export const KeywordsError = ({
  statusCode,
  onGenerate,
}: {
  statusCode?: FirestoreGenerationStatusCode | null;
  onGenerate: () => void;
}) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <BaseListContent
      title={t("productDetails:generatedKeywords.header")}
      isEmptyList
      emptyListProps={{
        label: t("productDetails:generatedKeywords.error.button.retry"),
        icon: "loader",
        onClick: onGenerate,
        title: t("productDetails:generatedKeywords.error.header"),
        titleStyle: "text-center",
        errorMessage:
          generationErrorMessage(t, statusCode) ||
          t("productDetails:generatedKeywords.error.message", {
            code: statusCode,
          }),
      }}
    />
  );
};
