import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { IProject } from "integrations/firebase/collections";
import { useAnalytics } from "contexts/AnalyticsContext";
import { ProjectsTable } from "components/projectsTable/ProjectsTable";
import { HomeHeader } from "./components/HomeHeader";
import { HomeSearch } from "./components/HomeSearch";
import { BacklistLinks } from "./components/BacklistLinks";
import { EvaluationResults } from "./components/EvaluationResults";

export const HomePage = () => {
  const { t } = useTranslation(["home"]);
  const { gaEvent } = useAnalytics();
  const navigate = useNavigate();

  const handleRowClicked = (row: IProject) => {
    navigate(RoutePath.ProjectDetails.replace(":idProject", row.id!));
    gaEvent({
      type: "view_item_list",
      payload: {
        item_list_name: row.name,
        item_list_id: row.id,
        item_category: "project",
      },
    });
  };

  return (
    <div>
      <div className="-mx-16 -mt-8 mb-8 bg-secondary-25 flex flex-col justify-center items-center gap-8 py-14 px-10">
        <HomeHeader />
        <HomeSearch />

        <div className="text-secondary-400 text-center">
          {t("home:search.description")}
        </div>
      </div>

      <div className="w-full bg-white space-y-10">
        <BacklistLinks />
        <EvaluationResults />

        <ProjectsTable
          onRowClicked={handleRowClicked}
          variant="compact"
          myProjectsOnly
        />
      </div>
    </div>
  );
};
