import { Icon, IconName } from "components/icon/Icon";
import { Input, InputProps } from "components/ui/input";
import { cn } from "utils";
import { KebabCase } from "utils/types";

interface IconInputProps extends InputProps {
  iconName: KebabCase<IconName>;
  value: string;
  className?: string;
  inputClassName?: string;
}

export const IconInput = ({
  iconName,
  type,
  className,
  inputClassName,
  ...rest
}: IconInputProps) => (
  <div
    className={cn(
      "flex items-center border border-gray-300 rounded-lg overflow-hidden",
      className,
    )}
  >
    <span className="px-3 text-gray-500">
      <Icon name={iconName} />
    </span>
    <Input
      className={cn(
        "flex-1 rounded-none border-0 border-l focus:outline-none focus:border-0 focus:border-l focus:shadow-none text-secondary-400",
        type === "date" && "custom-date-input",
        inputClassName,
      )}
      type={type}
      {...rest}
    />
  </div>
);
