import { EditorContent, useEditor } from "@tiptap/react";
import { generateHTML } from "@tiptap/core";

import { PropsWithChildren, useEffect } from "react";
import { extensions } from "./extensions";
import { IKeywords } from "./extensions/Keywords";
import { EditorActions } from "./EditorActions";
import { EditorProvider } from "./EditorProvider";

interface EditorProps {
  initialValue?: string;
  disabled?: boolean;
  keywords?: IKeywords;
  className?: string;

  onChange?(value: string): void;
}

export function Editor({
  initialValue,
  disabled,
  keywords,
  onChange,
  className,
  children,
}: PropsWithChildren<EditorProps>) {
  const editor = useEditor({
    extensions,
    content: initialValue,
    editable: !disabled,
    async onUpdate(props) {
      if (!props.transaction.docChanged) {
        return;
      }

      const html = generateHTML(props.transaction.doc.toJSON(), extensions);

      onChange?.(html);
    },
  });

  useEffect(() => {
    // remove empty keywords as they break the editor
    const keywordsToSet = {
      generated: keywords?.generated?.filter((g) => g.length > 0),
      database: keywords?.database?.filter((g) => g.length > 0),
    };
    editor?.commands.setKeywords(keywordsToSet);
  }, [keywords, editor]);

  return (
    <div className={className}>
      <EditorProvider editor={editor}>
        <div className="h-full flex flex-col">
          <EditorContent
            editor={editor}
            className="prose px-4 max-w-none overflow-y-auto flex flex-1"
            data-testid="editor"
          />
          <EditorActions editor={editor} />
          {children}
        </div>
      </EditorProvider>
    </div>
  );
}
