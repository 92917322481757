import { DialogClose } from "@radix-ui/react-dialog";
import { Button, ButtonProps } from "components/button";

import { IconProps } from "components/icon/Icon";
import { cn } from "utils/cn";

export function ModalFooter({
  id,
  confirmLabel,
  confirmIconName,
  confirmVariant,
  confirmClassName,
  cancelLabel,
  cancelIconName,
  loading,
  disabledConfirm,
  onConfirm,
}: {
  id: string;
  confirmLabel?: string;
  confirmIconName?: IconProps["name"];
  confirmVariant: ButtonProps["variant"];
  confirmClassName?: string;
  cancelLabel?: string;
  cancelIconName?: IconProps["name"];
  loading?: boolean;
  disabledConfirm?: boolean;
  onConfirm?: () => void;
}) {
  return (
    <div className="flex mx-auto items-center justify-center space-x-3">
      {cancelLabel && (
        <DialogClose asChild>
          <Button
            testId={`${id}-modal-cancel-button`}
            label={cancelLabel}
            variant="tertiary"
            size="small"
            icon={cancelIconName}
          />
        </DialogClose>
      )}
      {confirmLabel && onConfirm && (
        <Button
          testId={`${id}-modal-confirm-button`}
          label={confirmLabel}
          size="small"
          icon={confirmIconName}
          iconClassName={cn({ "animate-loader": loading })}
          disabled={loading || disabledConfirm}
          onClick={onConfirm}
          variant={confirmVariant}
          className={confirmClassName}
        />
      )}
    </div>
  );
}
