import { useFormContext } from "react-hook-form";
import { SearchType } from "hooks/search/types";
import { useTranslation } from "react-i18next";

import { InputWithClear } from "components/input/inputWithClear";
import { MultiTextInput } from "./MultiTextInput";

interface TextInputProps {
  name: string;
}

export function SearchInput({ name }: TextInputProps) {
  const { watch, register, resetField } = useFormContext();
  const { t } = useTranslation(["books"]);
  const typeOfSearch = watch ? watch("type") : SearchType.QUICK;
  const placeholder =
    typeOfSearch === SearchType.QUICK
      ? t(`books:search.placeholder.quick`)
      : t(`books:search.placeholder.semantic`);

  if (typeOfSearch === SearchType.ISBN) {
    return <MultiTextInput name={name} />;
  }
  return (
    <InputWithClear
      id="search-input"
      placeholder={placeholder}
      aria-label={placeholder}
      type="text"
      onClear={() => {
        resetField(name, { defaultValue: "" });
      }}
      value={watch(name)}
      className="border-l-0 rounded-lg rounded-l-none h-full"
      {...register(name)}
    />
  );
}
