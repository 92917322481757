import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import qs from "query-string";

import entries from "lodash/entries";

import {
  FilterType,
  SearchQuery,
  SearchQueryFilters,
  SearchType,
} from "./types";
import { paramsToSearchQuery } from "./utils";

const DEFAULT_PAGE_SIZE = 10;

export function queryToParams(query: Partial<SearchQuery>) {
  const queryFilters = entries(query.filters).map(([variable, queryFilter]) => {
    if (!queryFilter) return undefined;

    switch (queryFilter.type) {
      case FilterType.NUMERIC:
        return typeof queryFilter.value === "number"
          ? `${variable}.${queryFilter.comparisonOperator}:${queryFilter.value}`
          : undefined;
      case FilterType.DATE_RANGE: {
        const { from, to } = queryFilter.range ?? {};
        const isValidDateRange = from && to && new Date(from) <= new Date(to);
        return isValidDateRange ? `${variable}:[${from}..${to}]` : undefined;
      }

      default:
        return undefined;
    }
  });

  const params: Record<string, string | number | string[] | undefined> = {
    q: query.query || undefined,
    type: query.type === SearchType.QUICK ? undefined : query.type,
    filter: queryFilters.filter(Boolean) as string[],
    page: query.page || undefined,
    pageSize: query.pageSize || undefined,
  };

  return qs.stringify(params, {
    encode: false,
    skipNull: true,
    sort: false,
  });
}

export function useSearchQuery() {
  const [params] = useSearchParams();
  const push = useNavigate();
  const location = useLocation();

  const query = useMemo(() => paramsToSearchQuery(params), [params]);

  const setQuery = useCallback(
    (updated: Partial<SearchQuery>) => {
      const value = { ...query, ...updated };

      push(
        {
          pathname: location.pathname,
          search: queryToParams(value),
        },
        { replace: true },
      );
    },
    [query, push, location.pathname],
  );

  const removeQueryParameter = useCallback(
    (key: keyof SearchQueryFilters) => {
      const updatedFilters = { ...query.filters };
      delete updatedFilters[key];
      setQuery({ filters: updatedFilters });
    },
    [setQuery, query.filters],
  );

  return useMemo(() => {
    const value = {
      ...query,
      pagination: {
        pageIndex: query.page || 1,
        pageSize: query.pageSize || DEFAULT_PAGE_SIZE,
      },
    };

    return { value, setQuery, removeQueryParameter };
  }, [query, setQuery, removeQueryParameter]);
}
