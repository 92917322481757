import { LucideX } from "lucide-react";
import * as React from "react";
import { cn } from "utils/cn";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
  onClear?: () => void;
}

export const InputWithClear = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = "text", testId, onClear, ...props }, ref) => {
    const value = React.useRef(props.value || "");
    const hasFocusOutlineNone = className?.includes("focus:outline-none");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      value.current = e.target.value;

      props.onChange?.(e);
    };

    const handleClear = () => {
      value.current = "";
      onClear?.();
    };

    return (
      <div className="relative w-full">
        <input
          ref={ref}
          type={type}
          className={cn(
            "flex h-10 w-full rounded-xl border border-secondary-300 bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary-200 focus-visible:outline-none focus:shadow-outline-secondary disabled:cursor-not-allowed disabled:opacity-50",
            !hasFocusOutlineNone && "focus-visible:outline-none",
            value.current && onClear && "pr-10",
            className,
          )}
          data-testid={testId}
          {...props}
          onChange={handleInputChange}
        />
        {value.current && onClear && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-secondary-500 hover:scale-110 focus:scale-110 transition-transform"
            aria-label="Clear input"
          >
            <LucideX className="h-4 w-4" />
          </button>
        )}
      </div>
    );
  },
);

InputWithClear.displayName = "InputWithClear";
