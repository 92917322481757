import { Client } from "@hey-api/client-fetch";
import {
  exportProducts,
  generateProductDescription,
  generateProductDescriptionBulk,
  generateProductKeywords,
  generateProductKeywordsBulk,
  generateProductThema,
  exportProductsResponseSchema,
  generateProductDescriptionBulkResponseSchema,
  generateProductDescriptionResponseSchema,
  generateProductKeywordsBulkResponseSchema,
  generateProductKeywordsResponseSchema,
  generateProductThemaResponseSchema,
} from "__generated__/api";
import { parseResult } from "../parseHelper";

export interface DescriptionType {
  textType: string;
  audience: string;
}

export class ProductsApi {
  constructor(private client: Client) {}

  async exportProducts(isbns: string[]) {
    if (isbns.length === 0) {
      throw new Error("Product IDs array cannot be empty");
    }

    const response = await exportProducts({
      body: { productIds: isbns },
      client: this.client,
    });

    const result = parseResult(exportProductsResponseSchema, response, {});

    return result?.data;
  }

  async generateDescription(
    productId: string,
    options: {
      hash: string;
      targetGroup: string | null;
      specialRequest: string | null;
    },
  ) {
    const response = await generateProductDescription({
      path: { identifier: productId },
      body: {
        hash: options.hash,
        targetGroup: options.targetGroup,
        specialRequest: options.specialRequest,
      },
      client: this.client,
    });

    const result = parseResult(
      generateProductDescriptionResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async generateDescriptionsBulk(
    productIds: string[],
    descriptionTypes: DescriptionType[],
  ) {
    const response = await generateProductDescriptionBulk({
      body: { identifiers: productIds, filters: descriptionTypes },
      client: this.client,
    });

    const result = parseResult(
      generateProductDescriptionBulkResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async generateKeywords(productId: string) {
    const response = await generateProductKeywords({
      path: { identifier: productId },
      body: { generate: true },
      client: this.client,
    });

    const result = parseResult(
      generateProductKeywordsResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async generateKeywordsBulk(productIds: string[], autoFill?: boolean) {
    const response = await generateProductKeywordsBulk({
      body: {
        generate: true,
        identifiers: productIds,
        autoFill,
      },
      client: this.client,
    });

    const result = parseResult(
      generateProductKeywordsBulkResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async generateThema(productId: string) {
    const response = await generateProductThema({
      path: { identifier: productId },
      client: this.client,
    });

    const result = parseResult(
      generateProductThemaResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async autofillKeywords(productId: string) {
    const response = await generateProductKeywords({
      path: { identifier: productId },
      body: { autoFill: true },
      client: this.client,
    });

    const result = parseResult(
      generateProductKeywordsResponseSchema,
      response,
      {},
    );

    return result?.data;
  }
}
