import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  addProject,
  FirestoreProject,
  getPaginatedProjects,
  getProject,
  IProject,
  PROJECT_TYPE,
  updateProject,
} from "integrations/firebase/collections";
import { logError } from "shared/services/ErrorReporting";
import { useAuth } from "../../../contexts/AuthContext";
import { useAnalytics } from "../../../contexts/AnalyticsContext";

interface IProjectsContext {
  projects: IProject[];
  showOnlyMyProjects: boolean;
  setShowOnlyMyProjects: (value: boolean) => void;
  isLoadingRead: boolean;
  isLoadingCreate: boolean;
  isLoadingUpdate: boolean;
  errorRead?: Error;
  errorCreate?: Error;
  errorUpdate?: Error;
  updateProjectName: (idProject: string, name: string) => void;
  updateProjectIsbns: (idProject: string, isbns: string[]) => void;
  getProject: (idProject: string) => Promise<IProject | undefined>;
  createProject: (
    name: string,
    isbns: string[],
    type?: PROJECT_TYPE,
    searchOptions?: Record<string, string>,
  ) => Promise<string | undefined>;
  goNextPage: () => void;
  goBackPage: () => void;
  currentPage: number;
  pageSize: number;
  setPageSize: (value: number) => void;
  canGoNextPage: boolean;
  count: number;
  goToPage: (page: number) => void;

  fetchProjects(): Promise<void>;
}

export const ProjectsContext = createContext<IProjectsContext>(
  undefined as never,
);

export const ProjectsContextProvider = ({ children }: PropsWithChildren) => {
  const { authUser, userData } = useAuth();
  const pageDocs = useRef<IProject[]>([]);
  const [count, setCount] = useState(0);
  const [showOnlyMyProjects, setShowOnlyMyProjects] = useState(true);
  const { gaEvent } = useAnalytics();

  const [isLoadingRead, setIsLoadingRead] = useState(true);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [canGoNextPage, setCanGoNextPage] = useState(true);
  const [projects, setProjects] = useState<IProject[]>([]);

  const [errorRead, setErrorRead] = useState<Error>();
  const [errorCreate, setErrorCreate] = useState<Error>();
  const [errorUpdate, setErrorUpdate] = useState<Error>();

  const organisation = useMemo(
    () => userData?.organisation ?? null,
    [userData],
  );

  const fetchProjects = useCallback(async () => {
    if (!userData) return;
    setIsLoadingRead(true);
    setErrorRead(undefined);
    try {
      if (!organisation) {
        throw new Error(`No organisation found for user: ${userData.email}`);
      }

      const currentPageProjects = await getPaginatedProjects(
        organisation.id,
        showOnlyMyProjects ? userData.id : null,
        { pageSize, currentPage },
      );

      setCanGoNextPage(currentPageProjects.length > pageSize);

      if (currentPage === 0) {
        setCount(currentPageProjects.length);
      } else {
        setCount(currentPage * pageSize + currentPageProjects.length);
      }

      if (currentPageProjects.length > 0) {
        pageDocs.current[currentPage] =
          currentPageProjects[currentPageProjects.length - 2];
      }
      setProjects(currentPageProjects);
    } catch (error) {
      logError(error);
      setErrorRead(error as Error);
    }

    setIsLoadingRead(false);
  }, [showOnlyMyProjects, currentPage, organisation, pageSize, userData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [showOnlyMyProjects]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const goNextPage = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const goBackPage = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const goToPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const fetchProject = useCallback(async (projectId: string) => {
    setIsLoadingRead(true);

    setErrorRead(undefined);
    let loadedProject;
    try {
      loadedProject = getProject(projectId);
    } catch (error) {
      logError(error);
      setErrorRead(error as Error);
    }

    setIsLoadingRead(false);
    return loadedProject;
  }, []);

  const getProjectOrFetch = useCallback(
    async (id: string) => {
      const existingProject = projects.find((p) => p.id === id);
      if (existingProject) return existingProject;
      return fetchProject(id);
    },
    [fetchProject, projects],
  );

  const createProject = useCallback(
    async (
      name: string,
      isbns: string[],
      type: PROJECT_TYPE = PROJECT_TYPE.ISBNS,
      searchOptions: Record<string, string> = {},
    ) => {
      setIsLoadingCreate(true);
      setErrorCreate(undefined);
      try {
        if (!authUser) {
          throw new Error("CreateProject: No user found.");
        }
        if (!organisation) {
          throw new Error(
            `CreateProject: No organisation found for user: ${authUser.email}`,
          );
        }
        const { uid, photoURL, displayName, email } = authUser;
        const newProjectData: FirestoreProject = {
          name,
          type,
          isbns,
          user: {
            uid,
            photoURL,
            displayName,
            email,
          },
          organisation,
          createdAt: new Date(),
          searchOptions,
        };

        gaEvent({
          type: "create_project",
          payload: { books_count: isbns.length, type },
        });

        const newProjectRef = await addProject(newProjectData);
        const newProj = { ...newProjectData, id: newProjectRef.id };
        setProjects([newProj, ...projects]);
        setIsLoadingCreate(false);
        return newProjectRef.id;
      } catch (error) {
        logError(error);
        setErrorCreate(error as Error);
        setIsLoadingCreate(false);
      }
    },
    [authUser, organisation, projects, gaEvent],
  );

  const updateProjectName = useCallback(
    async (projectId: string, name: string) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);
      try {
        await updateProject(projectId, { name });

        setProjects((current) =>
          current.map((p) => (p.id === projectId ? { ...p, name } : p)),
        );

        setIsLoadingUpdate(false);
      } catch (error) {
        logError(error);
        setErrorUpdate(error as Error);
        setIsLoadingUpdate(false);
      }
    },
    [],
  );

  const updateProjectIsbns = useCallback(
    async (projectId: string, isbns: string[]) => {
      setIsLoadingUpdate(true);
      setErrorUpdate(undefined);
      try {
        await updateProject(projectId, { isbns });
        setProjects((current) =>
          current.map((p) => (p.id === projectId ? { ...p, isbns } : p)),
        );
        setIsLoadingUpdate(false);
      } catch (error) {
        logError(error);
        setErrorUpdate(error as Error);
        setIsLoadingUpdate(false);
        throw error;
      }
    },
    [],
  );

  const projectsContextProviderValue = useMemo(
    () => ({
      projects,
      showOnlyMyProjects,
      setShowOnlyMyProjects,
      isLoadingRead,
      isLoadingCreate,
      isLoadingUpdate,
      errorRead,
      errorCreate,
      errorUpdate,
      updateProjectName,
      updateProjectIsbns,
      currentPage,
      pageSize,
      count,
      setPageSize,
      canGoNextPage,
      goNextPage,
      goBackPage,
      getProject: getProjectOrFetch,
      createProject,
      goToPage,
      fetchProjects,
    }),
    [
      projects,
      showOnlyMyProjects,
      setShowOnlyMyProjects,
      isLoadingRead,
      isLoadingCreate,
      isLoadingUpdate,
      errorRead,
      errorCreate,
      errorUpdate,
      currentPage,
      updateProjectName,
      updateProjectIsbns,
      pageSize,
      count,
      setPageSize,
      canGoNextPage,
      goNextPage,
      goBackPage,
      getProjectOrFetch,
      createProject,
      goToPage,
      fetchProjects,
    ],
  );

  return (
    <ProjectsContext.Provider value={projectsContextProviderValue}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const ctxt = useContext(ProjectsContext);
  if (!ctxt) {
    throw new Error(
      "useProjects must be used within a ProjectsContextProvider",
    );
  }
  return ctxt;
};
